<template>

<div class="containerloading" style="z-index: 999999;">
    <div class="dots" style="top:50%;">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
    </div>
</div>

<div style="z-index: 999999;" class="toast-container position-fixed bottom-0 end-0 p-3 d-none" data-autohide="true">
  <div id="liveToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true"  data-delay="3500">
    <div class="toast-header " style="color:white; font-weight:bold; background: linear-gradient(45deg,#2ed8b6,#59e0c5);">

      <i class="fa fa-info-circle rounded  me-2"></i>
      <strong style="cursor: pointer;" id="naslov" class="me-auto">Notifications</strong>
      <button type="button"  style="font-weight:bold;" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>


    </div>
    <div class="toast-body" style="color:rgb(106, 105, 105);">

      <span id="tekst">Sucess</span>
    </div>
  </div>

 
</div>

  <router-view/>
</template>


<script>
import axios from 'axios'

export default{

  data(){
    return {

    }

  },
  
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token     
    } else {
        axios.defaults.headers.common['Authorization'] = ""

    }
  },





  mounted(){



  },
  methods:{

    getKlub(klubparam) {


//this.$store.state.id==klubparam
if(false){

this.root = document.documentElement;
console.log(this.$store.state.boja)
this.root.style.setProperty("--primary-color", this.$store.state.boja);
this.root.style.setProperty("--secondary-color", this.$store.state.secboja);
return;

}

else{


axios
.get('/getklubstaff/'+klubparam)
.then(res => {

  document.title = "Admin " + res.data.ime
localStorage.setItem("boja", res.data.boja)
localStorage.setItem("secboya", res.data.hoverboja)
localStorage.setItem("ime", res.data.ime)
localStorage.setItem("id", klubparam)
localStorage.setItem("logo", res.data.logo)

this.$store.commit('setIme', res.data.ime)
this.$store.commit('setBoja', res.data.boja)
this.$store.commit('setHover', res.data.hoverboja)
this.$store.commit('setId', klubparam)

this.root = document.documentElement;
this.root.style.setProperty("--primary-color", res.data.boja);
this.root.style.setProperty("--secondary-color", res.data.hoverboja);

})

.catch(error => {

console.log(error)

})

}




},


  

    toastnoti(naslov, tijelo, vrsta){
      if(vrsta=="uspjeh"){
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#2ed8b6,#59e0c5)');
  }
  else if(vrsta=="info"){
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#ed1650,#FF5370)');
  }
  else{
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#FF5370,#ff869a)');
    
  }

    $('.toast-container').removeClass('d-none');
    const toastLiveExample = document.getElementById('liveToast')
    
    $('#liveToast #tekst').text(tijelo);
    $('#liveToast #naslov').text(naslov);
    const toast = new bootstrap.Toast(toastLiveExample)
    toast.show()
    
  

  },
  }
}
</script>

<style lang="scss">

.toast{
  border: none !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
