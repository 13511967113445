<template>
    <Navigation>
        <template #body>
            <div class="body d-flex py-lg-3 py-md-2">
            <div class="container-xxl">
            <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="card border-0 mb-4 no-bg">
                            <div class="card-header py-3 px-0 d-sm-flex align-items-center  justify-content-between border-bottom">
                                <h3 class=" fw-bold flex-fill mb-0 mt-sm-0" style="text-align: left;padding-left: 30px;">Club Plans</h3><br>
             
                                <div style="padding:10px"><button type="button" @click="createform()" class="btn btn-dark me-1 mt-1 w-sm-100" ><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj</button></div>
                                
                                <div class="dropdown">
                              
                                    <ul class="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                    <li><a class="dropdown-item" href="#">All</a></li>
                                    <li><a class="dropdown-item" href="#">Task Assign Members</a></li>
                                    <li><a class="dropdown-item" href="#">Not Assign Members</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Row End -->
            <div class="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 row-deck py-1 pb-4">
                    <div class="col" v-for="i in this.planovi">
                        <div class="card teacher-card">
                            <div class="card-body d-flex">
                                <div class="profile-av pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
                                    <img :src="i[2]" alt="" class="avatar xl rounded-circle img-thumbnail shadow-sm">
                                    <div class="about-info d-flex align-items-center mt-3 justify-content-center">
                                        <div class="followers me-2" v-if="i[3]">
                                            <i class="fa fa-check fs-4" style="color:green"></i>
                                    
                                        </div>
                                        <div class="followers me-2" v-if="!i[3]">
                                            <i class="fa fa-magnifying-glass fs-4"></i>
                                    
                                        </div>
                                    
                                    </div>
                                </div>
                                <div class="teacher-info border-start ps-xl-4 ps-md-3 ps-sm-4 ps-4 w-100">
                                    <h6  class="mb-0 mt-2  fw-bold d-block fs-6">{{ i[0] }}</h6>
                                    <span class="light-success-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-11 mb-0 mt-1" @click="addstol(i[1])" style="color: white;"><span>Add table</span></span>
                                   
                                    
                                    <div class="row" style="padding-top:15px">
                                        <div class="col col-md-6 col-sm-4" v-for="j in i[4]" style="flex: 0 0 33%;padding: 10px;" @click="editstol(i[1], j.stol, j.lista)"> <span class="light-info-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-11 mb-0 mt-1" style="font-size: 15px;">{{ j.stol }}</span></div>
                     
                                    </div>
                                    <div class="video-setting-icon mt-3 pt-3 border-top">
                             
                                    </div>
                                    <a @click="deletemodal(i[1])" class="btn btn-dark btn-sm mt-1"><i class="fa fa-minus me-2 fs-6"></i>Delete Club</a>
                                 
                
                
                                </div>
                            </div>
                        </div>
                    </div>
                 
                  
            
                </div>

                <div class="card mb-3" v-if="this.planovi && !this.planovi.length">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"><i class="fa fa-table-cells-large"></i></span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>No table plans for now</span></h6><br>
                                                      
                                                        <span class="text-muted" style="font-size: 13px;" >Send us a drawing of the club and we will create a model for you. Choose model of the club when creating events.<br> Your users will be able to reserve a table by clicking on the tables.<br></span>
                                                    </div>
                                                </a>
                                               
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                            
                                       
                                            </div>
                                        </div>
                                       
                   
            </div>
        </div>
    
     
    
            <div class="modal fade" id="uploadslika" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Dodaj plan kluba</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Lokacija</label>
                        <input type="text" class="form-control" id="sub1" v-model="form.lokacija">
                    </div><br>
                    <div class="mb-3">
                        <label for="formFileMultipleoneone" class="form-label">Slika</label>
                        <p>Molimo vas na crtežu označite stolove koje želite da se mogu rezervirati kao i njihove redne brojeve kako biste ih razlikovali<br>Nakon što kliknete spremi možete dodati detalje za pojedine stolove.</p>
                        <input class="form-control" type="file" id="formFileMultipleoneone"  @change="handleFileChange">
                    </div>
                   
                   
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>

                    <button type="submit" class="btn btn-success" style="color:white" @click="postplan()">Spremi</button>
                </div>
            </div>
            </div>
        </div>
        
        <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Izbriši plan kluba ?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deleteevent()">Izbriši</button>
                </div>
            </div>
            </div>
        </div>


        
        <div class="modal fade" id="editstol" tabindex="-1"  aria-hidden="true" @hidden.bs.modal="modalClosed">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Tables</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="poststol()">
                <div class="modal-body" >
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Broj Stola</label>
                        <input type="number" class="form-control" id="sub1" v-model="stolform.broj">
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Min ljudi</label>
                        <input type="number" class="form-control" id="sub1" v-model="stolform.minljudi">
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Min Deposit</label>
                        <input type="number" class="form-control" id="sub1" v-model="stolform.mindeposit">
                    </div>
            
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deletestol()" v-if="!this.stolform.new">Izbriši</button>
                    <button type="submit" class="btn btn-success" style="color:white">Spremi</button>
                </div>
            </form>
            </div>
            </div>
        </div>



            
        </template>
    </Navigation>
    
            <!-- Body: Body -->
          
    
        
        </template>
        
        
        <script>
        import $ from 'jquery'
    
        import Navigation from '../components/Navigation.vue'
        import axios from 'axios'
        export default {
          name: 'EventiView',
        
        
          data(){
            return{
            planovi:null,
            form:{
                lokacija:"",
                img:null,
                klubid:this.$route.params.klub,
            },
            deleteform:{
            vrsta:"plan",
            id:0,
            klubid:this.$route.params.klub,
        },
        stolform:{
            minljudi:"",
            mindeposit:"",
            broj:"",
            planid:"",
            new:false,
            staribroj:"",
            delete:false,
        },
        editform:{
            minljudi:"",
            mindeposit:"",
            broj:"",
            planid:"",
            new:false,
            staribroj:"",
            delete:false,
        }

          }
        },
        components: {
        Navigation
      },
    
          mounted(){
            $(".containerloading").show()
           
    
            this.getPlanovi()
        
        
          },
          methods:{
            modalClosed(){
                console.log("closes")
            },

            deletestol(id){
     
  this.stolform.delete = true
  this.poststol()
},  


            editstol(id, stol, lista){
                this.stolform.staribroj = stol
                this.stolform.planid = id
                this.stolform.broj = stol
                this.stolform.mindeposit = lista[0]
                this.stolform.minljudi = lista[1]
                this.stolform.new = false
     
                
                window.$("#editstol").modal('show')


    },

            addstol(id){
                this.stolform.broj = null
        this.stolform.mindeposit = null
        this.stolform.minljudi = null
        this.stolform.delete = false
                this.stolform.planid = id
                this.stolform.new = true
        window.$("#editstol").modal('show')


    },

            postplan(){

axios.post('/klub/postplan/', this.form, {headers: {
        'Content-Type': 'multipart/form-data',
      },})
    .then((res) => {
            console.log(res.data)
        this.planovi.push([this.form.lokacija, res.data.id, res.data.img, false])
        this.$root.toastnoti('Uspjeh', 'Plan je objavljen', 'uspjeh')
    
        this.form.lokacija = ""
        this.form.img = null
        window.$("#uploadslika").modal('hide')
            
      })
    .catch((error) => {
        this.$root.toastnoti('Greška', 'Greška pri kreiranju plana', 'greska')
        

    })

},

poststol(){
    window.$(".containerloading").show()
axios.post('/addstol/', this.stolform)
    .then((res) => {
        
        window.$("#editstol").modal('hide')
        if(this.stolform.new && !this.stolform.delete){
            this.$root.toastnoti('Uspjeh', 'Stol je dodan', 'uspjeh')
            this.planovi[0][4].push({'stol':this.stolform.broj, 'lista':[this.stolform.mindeposit, this.stolform.minljudi, true]})
            for(var i=0; i<this.planovi.length; i++){
        this.planovi[i][4].sort((a, b) => a.stol - b.stol);

       }


        
        }
        if(!this.stolform.new && !this.stolform.delete){
            const result = Object.values(this.planovi[0][4]).find(item => item.stol === this.stolform.staribroj);
            result.stol = this.stolform.broj
            result.lista = [this.stolform.mindeposit, this.stolform.minljudi, true]
            this.$root.toastnoti('Uspjeh', 'Informacije o stolu su izmijenjene', 'uspjeh')


        

        }
        if(this.stolform.delete){
            this.$root.toastnoti('Uspjeh', 'Stol je izbrisan', 'uspjeh')
            this.planovi[0][4] = this.planovi[0][4].filter(item => item.stol != this.stolform.broj);
            for(var i=0; i<this.planovi.length; i++){
        this.planovi[i][4].sort((a, b) => a.stol - b.stol);

       }

  
        }
       

        this.stolform.broj = null
        this.stolform.mindeposit = null
        this.stolform.minljudi = null
        this.stolform.delete = false
        

            
      })
    .catch((error) => {
        console.log(error)
        this.$root.toastnoti('Greška', 'Greška pri izradi stola', 'greska')
        

    })
    window.$(".containerloading").hide()

},


            deleteevent(){
    
    axios.post('/deletepost/', this.deleteform)
    .then((res) => {
        this.$root.toastnoti('Success', 'Club plan is deleted', 'uspjeh')
        window.$("#dremovetask").modal('hide')

        
        this.planovi = this.planovi.filter(item => {
        if (item[1] === this.deleteform.id) {
          return false; 
        }
        return true
      });


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            deletemodal(id){
                this.deleteform.id = id
        window.$("#dremovetask").modal('show')


    },
    createform(){
        window.$("#uploadslika").modal('show')


    },
            handleFileChange(event) {
      this.form.img = event.target.files[0];
    },

            async getPlanovi() {
    
    await axios
      .get('/klub/planovi/'+this.$route.params.klub)
      .then(res => {

       this.planovi = res.data.planovi
       for(var i=0; i<this.planovi.length; i++){
        this.planovi[i][4].sort((a, b) => a.stol - b.stol);

       }



      $(".containerloading").hide()
        
    
      
      })
      .catch(error => {
        if(error.response.status == 401){
            this.$router.push('/login/')
                    }
        this.$root.toastnoti('Greška', 'Greška pri dohvaćanja narudžba', 'greska')
        $(".containerloading").hide()
      })
    
    },
    
          
          
            
          },
        }
        </script>
        
    