<template>
   
<Navigation>
    
    <template #body>
        
     
        <div class="body d-flex py-lg-3 py-md-2">
            <div class="container-xxl">
                
              <br>

                <div class="row justify-content-center">

                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="Invoice-list">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="card mb-3" v-for="i in orders">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"  v-if="i[1] == 'Bar'">{{ i[1][0] }}</span>
                                                    <span class="stepnum"  v-else>{{ i[1] }}</span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>       <i class="fa fa-xmark" v-if="i[4]=='cash'" style="color:red"></i>
                                                        <i class="fa fa-check" v-if="i[4]=='card'" style="color:lightgreen"></i> {{i[0].charAt(0).toUpperCase() + i[0].slice(1)}}, {{ i[1] }}, <b>ID: {{ i[2] }}</b></span></h6>
                                              <br>
                                                        <span class="text-muted" style="font-size: 15px;" v-for="j in i[3]">{{ j[0] }}  <b style="font-weight:bold !important;">{{ j[1] }}x</b><br></span>
                                                        <span class="text-muted" style="font-size: 15px;" v-for="j in i[5]">{{ j[1] }}  <b style="font-weight:bold !important;">1x {{ j[0] }}%</b><br></span>
                                           
                                                        <span class="text-muted" style="font-size: 15px;"> <b style="font-weight:bold !important;">Total: {{i[6]}}€</b><br></span>
                                             
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                                    <a class="btn btn-sm btn-white border lift" @click="zavrsinarudzbu(i[2])">Završi</a>
                                
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-3" v-if="orders && orders.length == 0">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"><i class="fa fa-martini-glass"></i></span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>No orders for now</span></h6>
                                                        <span class="text-muted" style="font-size: 15px;" ><br></span>
                                                    </div>
                                                </a>
                                               
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                            
                                       
                                            </div>
                                        </div>
                                       
                                      
                                      
                           
                                    
                                    </div>
                                </div>  <!-- Row end  -->
                            </div> <!-- tab end  -->
                     
                            <div class="tab-pane fade" id="Invoice-Email">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="d-flex justify-content-center">
                                            <table class="card p-5">
                                                <tr>
                                                    <td></td>
                                                    <td class="text-center">
                                                        <table>
                                                            <tr>
                                                                <td class="text-center">
                                                                    <h2>$48.98 Paid</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center py-2">
                                                                    <h4 class="mb-0">Thanks for using PXL Inc.</h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pt-2 pb-4">
                                                                    <table>
                                                                        <tr>
                                                                            <td>
                                                                                Attn: <strong>Ryan MacLeod</strong> Winston Salem FL 27107<br>
                                                                                Email: RyanmacLeod@gmail.com<br>
                                                                                Phone: +88 123 456 789<br>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="pt-2">
                                                                                <table class="table table-bordered">
                                                                                    <tr>
                                                                                        <td class="text-start">Extended License</td>
                                                                                        <td class="text-end">$ 20.99</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start">1 year subcription</td>
                                                                                        <td class="text-end">$ 19.99</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start">Instalation and Customization</td>
                                                                                        <td class="text-end">$ 8.00</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start w-80"><strong>Total</strong></td>
                                                                                        <td class="text-end fw-bold">$ 48.98</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pt-2 pb-2 text-center">
                                                                    <a href="#">View in browser</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="p-0 text-center">
                                                                    PXL Inc. 47 Aurora St. South West, CT 06074
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="mt-3 text-center w-100">
                                                            <tr>
                                                                <td class="aligncenter content-block">Questions? Email <a href="mailto:">info@pixelwibes.com</a></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div> <!-- Row end  -->
                            </div> <!-- tab end  -->
                        </div>
                    </div>

                </div> <!-- Row end  -->
            </div>
        </div>

        <!-- Modal Members-->
        <div class="modal fade" id="addUser" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="addUserLabel">Employee Invitation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="inviteby_email">
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email address" id="exampleInputEmail1" aria-describedby="exampleInputEmail1">
                            <button class="btn btn-dark" type="button" id="button-addon2">Sent</button>
                        </div>
                    </div>
                    <div class="members_list">
                        <h6 class="fw-bold ">Employee </h6>
                        <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar2.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Rachel Carr(you)</h6>
                                        <span class="text-muted">rachel.carr@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <span class="members-role ">Admin</span>
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icofont-ui-settings  fs-6"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li><a class="dropdown-item" href="#"><i class="icofont-ui-password fs-6 me-2"></i>ResetPassword</a></li>
                                              <li><a class="dropdown-item" href="#"><i class="icofont-chart-line fs-6 me-2"></i>ActivityReport</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar3.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Lucas Baker<a href="#" class="link-secondary ms-2">(Resend invitation)</a></h6>
                                        <span class="text-muted">lucas.baker@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Members
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li>
                                                  <a class="dropdown-item" href="#">
                                                    <i class="icofont-check-circled"></i>
                                                      
                                                    <span>All operations permission</span>
                                                   </a>
                                                   
                                                </li>
                                                <li>
                                                     <a class="dropdown-item" href="#">
                                                        <i class="fs-6 p-2 me-1"></i>
                                                           <span>Only Invite & manage team</span>
                                                       </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icofont-ui-settings  fs-6"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li><a class="dropdown-item" href="#"><i class="icofont-delete-alt fs-6 me-2"></i>Delete Member</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar8.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Una Coleman</h6>
                                        <span class="text-muted">una.coleman@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Members
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li>
                                                  <a class="dropdown-item" href="#">
                                                    <i class="icofont-check-circled"></i>
                                                      
                                                    <span>All operations permission</span>
                                                   </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="fs-6 p-2 me-1"></i>
                                                           <span>Only Invite & manage team</span>
                                                       </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="btn-group">
                                            <div class="btn-group">
                                                <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="icofont-ui-settings  fs-6"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-ui-password fs-6 me-2"></i>ResetPassword</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-chart-line fs-6 me-2"></i>ActivityReport</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-delete-alt fs-6 me-2"></i>Suspend member</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-not-allowed fs-6 me-2"></i>Delete Member</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="full-screen-div fade-background" @click="hidenotice()" v-if="notice">
  <img src="../assets/images/logonew.png" alt="Logo" class="logo-img"> <!-- Your logo with <img> tag -->

  <div class="alert-text">  <i class="fa-solid fa-martini-glass-citrus"></i> New Order!</div>
  <p class="small" style="color:white;font-size: 13px;">Click anywhere to close</p>
</div>
    </template>
</Navigation>

        <!-- Body: Body -->
      

    
    </template>
    
    
    <script>
    import $ from 'jquery'

    import Navigation from '../components/Navigation.vue'
    import axios from 'axios'
    export default {
      name: 'EventiView',
    
    
      data(){
        return{
        orders:[],
        intervalId1:null,
        notice:false,
        orderIds:[],
        previds:[],
        first:true,
      }
    },
    components: {
    Navigation
  },
  beforeRouteLeave(to, from, next) {
    // Stop polling when leaving the route
    clearInterval(this.intervalId1);
    next();
  },

      mounted(){
        
        $(".containerloading").show()
        this.getOrders();
        this.intervalId1 = null
        this.intervalId1 = setInterval(() => {
            this.getOrders();
      }, 10000);
      
    
    
      },
      methods:{
        hidenotice(){
            this.notice=false
        },

        async getOrders() {

await axios
  .get('/klub/orders/'+this.$route.params.klub)
  .then(response => {
    this.orders = response.data.orders

    
    this.previds = this.orderIds
    this.orderIds = this.orders.map(order => order[2]);
    const newids = this.orderIds.filter(id => !this.previds.includes(id));
    console.log(this.previds)
    console.log(newids)
    console.log(this.first)

    if (newids.length > 0 && !this.first) {
        this.notice = true
    }
    this.first = false

   

  $(".containerloading").hide()
    

  
  })
  .catch(error => {
    console.log(error)
    if(error.response.status == 401){
        this.$router.push('/login/')
                }
    this.$root.toastnoti('Greška', 'Greška pri dohvaćanja narudžba', 'greska')
    $(".containerloading").hide()
  })

},

        zavrsinarudzbu(id){
            window.$(".containerloading").show()
            
             axios
  .get('/klub/finishorder/'+id)
  .then(response => {
        this.$root.toastnoti('Uspjeh', 'Narudžba je završena', 'uspjeh')
        this.orders =  this.orders.filter(list => list[2] !== id);

    $(".containerloading").hide()
        

  
  })
  .catch(error => {
    console.log(error)
    this.$root.toastnoti('Greška', 'Greška pri završavanju narudžbe', 'greska')
    $(".containerloading").hide()
  })

           
        },
      
        
      },
    }
    </script>
    

    
  <style>

@keyframes fadeBackgroundColor {
 
  0% {
    background-color: var(--primary-color); /* Replace #color1 with your starting color */
  }
  50% {
    background-color: #d64b71 /* Replace #color2 with your ending color */
  }
  100% {
    background-color: var(--primary-color); /* Replace #color1 with your starting color */
  }
}

.fade-background {
  animation-name: fadeBackgroundColor;
  animation-duration: 2.5s; /* Adjust time as needed */
  animation-iteration-count: infinite;
}
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
  .full-screen-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    
    flex-direction: column; /* Stack the text and logo vertically */
    justify-content: center;
    align-items: center;
  }

  .alert-text {
    font-size: 28px; /* Large font size for visibility */
    color: white; /* Choose a color that contrasts well with both backgrounds */
    font-family: "Hanken Grotesk", sans-serif;
    margin-bottom: 10px; /* Space between the text and the logo */
  }

  .logo-img {
    max-width: 250px;
    max-height: 100px;
    margin-top: -100px; /* Adjust if necessary */
  }

  .order-card {
      color: #fff;
  }
  
  .bg-c-blue {
      background: linear-gradient(45deg,#4099ff,#73b4ff);
  }
  
  .bg-c-green {
      background: linear-gradient(45deg,#2ed8b6,#59e0c5);
  }
  
  .bg-c-yellow {
      background: linear-gradient(45deg,#FFB64D,#ffcb80);
  }
  
  .bg-c-pink {
      background: linear-gradient(45deg,#FF5370,#ff869a);
  }
  .bg-c-gray {
      background: linear-gradient(45deg,#d3d3d3,#d3d3d3);
  }
  
  
  
  .card {
      border-radius: 5px;
      -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      border: none;
      margin-bottom: 15px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
  
  .card .card-block {
      padding: 25px;
  }
  
  .order-card i {
      font-size: 26px;
  }
  
  .f-left {
      float: left;
  }
  
  .f-right {
      float: right;
  }
  
  
  </style>
  
  <style scoped>
  
  .project {  
  
      max-width: 100%;  
  
  }  
  
  
  .submitbtn{
      font-weight: 400;
      display: inline-block;
      padding: 10px 28px;
      border-radius: 4px;
      transition: 0.5s;
      color: var(--color-white);
      background: var(--color-primary);
      font-family: var(--font-secondary);
  
  }

  .stepnum{
max-height: 1.6em;
  background: #ed1650;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; 
  font-size: 20px !important;

  }
  


  </style>
  