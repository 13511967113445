<template>




 <Navigation>
    <template #body>

           <!-- Body: Body -->       
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Detalji eventa</h3>
                    </div>
                </div>
            </div> <!-- Row end  -->
            <div class="row g-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div class="row g-3 mb-3">
                        <div class="col-md-4">
                            <div class="card ">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar lg  rounded-1 no-thumbnail bg-lightyellow color-defult"><i class="icofont-optic fs-4"></i></div>
                                        <div class="flex-fill ms-4 text-truncate">
                                            <div class="text-truncate">Status</div>
                                            <span class="badge bg-danger" style="font-size: 12px;" v-if="!event.web && !event.instagram && !event.facebook">Nije još reklamirano</span>
                                            <span class="badge bg-success" style="font-size: 12px;" v-else>Reklamirano</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
          

              
                    </div> <!-- Row end  -->
                    
                    <div class="row g-3" >
                        <div class="col-md-12" >
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="depone" class="form-label"><b style="color:gray;">Naslov</b></label>
                            <input type="text" class="form-control" id="depone"  v-model="editform.naslov"><br>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="depone" class="form-label"><b style="color:gray;">Lokacija</b></label>
                            <input type="text" class="form-control" id="depone"  v-model="editform.lokacija" disabled><br>

                                        </div>

                                        <div class="col-md-6">
                                            <label for="depone" class="form-label"><b style="color:gray;">Datum</b></label>
                            <input type="date" class="form-control"  v-model="editform.datum" id="deptwo"><br>
                                        </div>
                             <div class="col-md-6">
                                            <label for="depone" class="form-label"><b style="color:gray;">Rezervacije stolova</b></label>
                                            <select class="form-select" aria-label="Default select example" v-model="this.editform.rezervacije">
  <option value="0">Besplatno</option>
  <option value="1">S Depozitom</option>
  <option value="2">Ugašeno</option>
</select><br>

                                        </div>

                    

                    
                                    </div>
                                    <br>
                            <label for="depone" class="form-label"><b style="color:gray;">Opis</b></label>
                                    <textarea class="form-control" id="exampleFormControlTextarea78" rows="7" v-model="editform.opis"></textarea><br>

              

                       

                                        <button type="button" class="btn btn-success btn-set-task w-sm-100" style="color:white;" @click="editEvent"><i class="icofont-check me-2 fs-6"></i>Spremi promjene</button>
                                </div> 
                            </div>
                            <div class="row align-items-center">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Tickets</h3>
                            <div class="col-auto d-flex w-sm-100">
                                
                            <!--    <button class="btn btn-success" style="color:white" v-if="editform.karte" @click="togglekarte()">Ticket Sale Open</button>
                                    <button class="btn btn-danger" style="color:white" v-if="!editform.karte" @click="togglekarte()">Ticket Sale Closed</button> &nbsp;-->

                                <button type="button" class="btn btn-dark btn-set-task w-sm-100" @click="openmodaladd()"><i class="icofont-plus-circle me-2 fs-6"></i>Add Tickets</button>
                            </div>
                        </div>
                    </div>
                </div> <!-- Row end  -->
                            <div class="row clearfix g-3" style="margin-bottom: 50px;">
                  <div class="col-sm-12">
                        <div class="card mb-3">
                            <div class="card-body">
                                <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                    <thead>
                                        <tr>
                                          <th>Actions</th>  
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Valid from</th> 
                                            <th>Valid until</th> 
                                            <th>Amount</th>   
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="i in  event.tickets">
                                          <td>
                                                <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                    <a style="padding-right: 15px;"><button type="button" class="btn btn-outline-secondary" @click="openticketedit(i[5])" ><i class="icofont-info text-success"></i></button></a>
                                                    <button type="button" class="btn btn-outline-secondary deleterow" @click="this.deletemodal(i[5])"><i class="icofont-ui-delete text-danger"></i></button>
                                                </div>
                                            </td>
                                            <td>
                                                <a class="fw-bold">{{ i[0] }}</a>
                                            </td>
                                            <td>
                                               {{i[4]}}€
                                           </td>
                                           <td>
                                            {{new Date(i[1]).toLocaleString('uk', {day: '2-digit', month: '2-digit'})}}
                                   
                                           </td>
                                           <td>
                                            {{new Date(i[2]).toLocaleString('uk', {day: '2-digit', month: '2-digit'})}}
                                           </td>
                                           <td><span class="badge bg-warning"> {{ i[3] }}</span></td>
                                           
                                        </tr>
                                        <tr v-if="event.tickets && !event.tickets.length">

                                            <td>
                                                <a  class="fw-bold text-secondary">No tickets</a>
                                            </td>
                                            <td>
                                       
                                           </td>
                                           <td>
                                           
                                           </td>
                                           <td>
                                           
                                           </td>
                                           <td></td>
                                            <td>
                                
                                            </td>

                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                  </div>
                 
                  <div id="chart" v-if="event.tickets && event.tickets.length">
        <apexchart type="radialBar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>

                </div><!-- Row End -->

                            <div class="row g-3">  
                                <div class="col-lg-4 col-md-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="fw-bold mb-3 text-danger">Prikvačene slike</h6>

                                            <button type="button" class="btn btn-dark btn-set-task w-sm-100" @click="openslikaupload()"><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj Sliku</button>
                                            <div class="flex-grow-1"><br>
                                         
                                                <div class="py-2 d-flex align-items-center border-bottom" v-for="i in event.slike">
                                                    <div class="d-flex ms-3 align-items-center flex-fill">
                                                        <span class="avatar lg light-danger-bg rounded-circle text-center d-flex align-items-center justify-content-center"><div class="circle-image">
  <img :src="i[0]" alt="Your Image" class="small-image">
</div></span>
                                                        <div class="d-flex flex-column ps-3">
                                                            <h6 class="fw-bold mb-0 small-14">Slika {{i[1]}}</h6>&nbsp;
                                       
                                                        </div>
                                                    </div>
                                                    <a :href="i[0]"><button type="button" class="btn light-danger-bg text-end"><i class="fa fa-download"></i></button></a>&nbsp;
                                                    <button type="button" class="btn light-danger-bg text-end" @click="openslikamodal(i[2], i[1])"><i class="fa fa-pencil"></i></button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="body col-md-4 col-lg-4" >
        <div class="container-xxl">
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Rezervacije stolova</h3>
                        <div class="col-auto d-flex w-sm-100">
                    
                        </div>
                    </div>
                </div>
            </div> <!-- Row end  -->
            <div class="row clearfix g-3">
              <div class="col-sm-12">
                    <div class="card mb-3">
                        <div class="card-body">
                            <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Table Number</th>
                                        <th>Reservation Type</th> 
                                      
                                    </tr>
                                </thead>
                                <tbody>
                                  
                                    <template v-for="i in event.stolovi">

                                        <tr v-if="!i.lista[2]">
                                       
                                       <td style="background-color: rgba(255,0,0, 0.5);
">
                                           <a href="/details/" class="fw-bold text-light">{{ i.stol }}</a>
                                       </td>
                                      
                                       <td><span class="badge bg-danger" >{{ i.lista[3][4] }}</span></td>
                                      

                                        <td>
                                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                               <button type="button" class="btn btn-outline-secondary" @click="openmodal(i.lista[3][1], i.lista[3][2], i.lista[3][3] ,true, i.lista[3][5], i.lista[3][0], i.lista[3][4])"><i class="icofont-check text-success"></i></button>
                              
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="i.lista[2]">
                                       
                                       <td style="background-color: rgba(40, 167, 69, 0.5)" >
                                           <a href="/details/" class="fw-bold text-light">{{ i.stol }}</a>
                                       </td>
                                      
                                       <td><span class="badge bg-warning">Prazno</span></td>
                                      

                                        <td>
                                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                               <button type="button" class="btn btn-outline-secondary" @click="openmodal(' ', ' ', ' ', false, 0, i.stol, ' ')"><i class="icofont-check text-success"></i></button>
                              
                                            </div>
                                        </td>
                                    </tr>


                                    </template>
                                   

                                    

                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
              </div>
            </div><!-- Row End -->
        </div>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                                <div class="card">
                                    <div class="card-header py-3">
                                        <h6 class="mb-0 fw-bold " style="color:Gray;font-size: 20px;">Marketing</h6><br>
                                
                                    </div>
                                    <div class="card-body">
                                        <div class="flex-grow-1 mem-list" style="height: 250px !important; ">

                                            <div class="py-2 d-flex align-items-center border-bottom">
                                                
                                                <div class="d-flex ms-2 align-items-center flex-fill">
                                                    <img :src="$store.state.logo" style="width:60px;" alt="logo">
                                                    <div class="d-flex flex-column ps-2">
                                                        <h6 class="fw-bold mb-0">NightWise {{$store.state.ime}}</h6>
                                             
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-info text-end" style="color:white;" @click="openreklamiranjeform('objavi_web', 'Event će biti vidljiv na vašoj web stranici i moći će se rezervirati stolovi.')" v-if="!this.event.web">Publish</button>&nbsp;
                                                <button type="button" class="btn btn-danger text-end" style="color:white;" @click="openreklamiranjeform('odjavi_web', 'Event više neće biti vidljiv na vašoj web stranici')" v-if="this.event.web">Unpublish</button>
                                            </div>

                                            <div class="py-2 d-flex align-items-center border-bottom">
                                                
                                                <div class="d-flex ms-2 align-items-center flex-fill">
                                                    <img src="../assets/images/gmail.png"  class="avatar lg rounded-circle img-thumbnail"  alt="avatar">
                                                    <div class="d-flex flex-column ps-2">
                                                        <h6 class="fw-bold mb-0">Email to customers</h6>
                                                       
                                             
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-info text-end" style="color:white;" @click="openreklamiranjeform('objavi_email', 'Email će biti poslan svim klijentima')" v-if="!this.event.email">Publish</button>
                                            </div>

                                            <div class="py-2 d-flex align-items-center border-bottom">
                                                
                                                <div class="d-flex ms-2 align-items-center flex-fill">
                                                    <img src="../assets/images/gmail.png"  class="avatar lg rounded-circle img-thumbnail" alt="avatar">
                                                    <div class="d-flex flex-column ps-2">
                                                        <h6 class="fw-bold mb-0">Email podsjetnik</h6>
                                                        <span class="small text-muted">Email podsjetnik svim klijentima</span> 
                                             
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-success text-end" style="color:white;" >{{event.podsjetnik}}</button>&nbsp;
                                                <button type="button" class="btn btn-info text-end" style="color:white;" @click="openreklamiranjeform('objavi_emailpodsjetnik', 'Email podsjetnik će biti poslan svim klijentima')">Objavi</button>
                                            </div>


                      

                                            
                                      
                                        </div>
                                    </div>
                                </div> <!-- .card: My Timeline -->
                            </div>
       

                              
                            </div>
                        </div>
                    </div> <!-- Row end  -->
                </div>
        


                      



            </div>
        </div>
    </div>

    
    </template>
 </Navigation>
    


    <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel"> Reklamacija eventa</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-share text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">{{porukashare}}</p>
                    <p class="mt-4 fs-5 text-center">Jeste li sigurni?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Odustani</button>
                    <button type="button" class="btn btn-info color-white" style="color:white;" @click="reklamirajpost()">Potvrdi</button>
                </div>
            </div>
            </div>
        </div>
    
    <div class="modal fade" id="edittickit" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Rzervacija Stola broj {{ rezform.stol }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Ime i prezime</label>
                        <input type="text" class="form-control" id="sub1" v-model="rezform.ime">
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Email</label>
                        <input type="text" class="form-control" id="sub1"  v-model="rezform.email">
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Telefon</label>
                        <input type="text" class="form-control" id="sub1"  v-model="rezform.telefon">
                    </div>

                    <div class="mb-3">
                        <label for="sub1" class="form-label">Vrsta rezervacije</label>
                        <input type="text" class="form-control" id="sub1"  v-model="rezform.vrstarez">
                    </div>
                   
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger" @click="deleterez()" style="color:white" v-if="this.rezform.edit">Izbriši</button>
                    <button type="submit" class="btn btn-success" style="color:white" @click="createrez()">Spremi</button>
                </div>
            </div>
            </div>
        </div>

        <div class="modal fade" id="editslika" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Uredi sliku</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Redni broj</label>
                        <input type="number" class="form-control" id="sub1" v-model="slikaform.rbr">
                    </div>
                   
                   
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color:white" @click="deleteimg">Izbriši</button>
                    <button type="submit" class="btn btn-success" style="color:white" @click="changerednibroj()">Spremi</button>
                </div>
            </div>
            </div>
        </div>


        <div class="modal fade" id="uploadslika" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Dodaj sliku</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label">Redni broj</label>
                        <input type="number" class="form-control" id="sub1" v-model="uploadslika.rbr">
                    </div><br>
                    <div class="mb-3">
                        <label for="formFileMultipleoneone" class="form-label">Slika</label>
                        <input class="form-control" type="file" id="formFileMultipleoneone"  @change="handleFileChange">
                    </div>
                   
                   
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>

                    <button type="submit" class="btn btn-success" style="color:white" @click="uploadslikaform()">Spremi</button>
                </div>
            </div>
            </div>
        </div>

        <div class="modal fade" id="tickadd" tabindex="-1"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title  fw-bold" id="leaveaddLabel">Create new ticket</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createTicket()">
            <div class="modal-body">
                <div class="mb-3">
                    <label for="sub" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="ticket.naslov"   id="sub" required>
                </div>
                <div class="deadline-form">
                   
                        <div class="row g-3 mb-3">
                       

                            <div class="col col-md-12 col-sm-12">
                            <label for="deptwo" class="form-label">Price</label>
                            <input type="number" class="form-control"  v-model="ticket.cijena"  id="deptwo">
                          </div>

                        </div>
                        <div class="row g-3 mb-3">
                       


                     <div class="col col-md-12 col-sm-12">
                       <label for="deptwo" class="form-label">Valid from</label>
                       <input type="date" class="form-control" id="deptwo" v-model="ticket.datumod"  required>
                     </div>
                     
                
                   </div>
                   <div class="row g-3 mb-3">
                       


                       <div class="col col-md-12 col-sm-12">
                         <label for="deptwo" class="form-label">Valid until</label>
                         <input type="date" class="form-control" id="deptwo" v-model="ticket.datumdo"  required>
                       </div>
                       
                  
                     </div>
                      
                   
                </div>
                <div class="mb-3">
                        <label for="exampleFormControlTextarea78" class="form-label">Amount</label>
                        <input type="number" class="form-control" v-model="ticket.kolicina"   id="deptwo" required>
                    </div>

                  
            </div>
            <div class="modal-footer">
      
                <button type="submit" class="btn btn-primary">Dodaj</button>
            </div>
        </form>
        </div>
        </div>
    </div>

    <div class="modal fade" id="ticketedit" tabindex="-1"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title  fw-bold" id="leaveaddLabel">Edit Ticket</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="editTicket()">
            <div class="modal-body">
                <div class="mb-3">
                    <label for="sub" class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="ticketedit.naslov"   id="sub" required>
                </div>
                <div class="deadline-form">
                   
                        <div class="row g-3 mb-3">
                       

                            <div class="col col-md-12 col-sm-12">
                            <label for="deptwo" class="form-label">Price</label>
                            <input type="number" class="form-control"  v-model="ticketedit.cijena"  id="deptwo">
                          </div>

                        </div>
                        <div class="row g-3 mb-3">
                       


                     <div class="col col-md-12 col-sm-12">
                       <label for="deptwo" class="form-label">Valid from</label>
                       <input type="date" class="form-control" id="deptwo" v-model="ticketedit.datumod"  required>
                     </div>
                     
                
                   </div>
                   <div class="row g-3 mb-3">
                       


                       <div class="col col-md-12 col-sm-12">
                         <label for="deptwo" class="form-label">Valid until</label>
                         <input type="date" class="form-control" id="deptwo" v-model="ticketedit.datumdo"  required>
                       </div>
                       
                  
                     </div>
                      
                   
                </div>
                <div class="mb-3">
                        <label for="exampleFormControlTextarea78" class="form-label">Amount</label>
                        <input type="number" class="form-control" v-model="ticketedit.kolicina"   id="deptwo" required>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea78" class="form-label">Description</label>
                        <textarea class="form-control" v-model="ticketedit.opis"   id="deptwo" required></textarea>
                    </div>

                  
            </div>
            <div class="modal-footer">
      
                <button type="submit" class="btn btn-primary">Edit</button>
            </div>
        </form>
        </div>
        </div>
    </div>

    
    <div class="modal fade" id="removeticket" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Delete Ticket?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">You will delete this ticket</p>
                </div>
                <div class="modal-footer">
      
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deleteticket()">Delete</button>
                </div>
            </div>
            </div>
        </div>


  
    

</template>



<script>
import $ from 'jquery'
import '../assets/bundles/dataTables.bundle.js';
import '../assets/bundles/libscripts.bundle.js';


import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import Navigation from '../components/Navigation.vue'
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue3-apexcharts'
DataTable.use(DataTablesCore);

import axios from 'axios'
export default {
  name: 'EventiView',
  components: {
    DataTable,
    Navigation,
    apexchart: VueApexCharts,

  },

  data(){
    return{
       id:null,
       event:"",
       ticket:{
        naslov:"",
        cijena:null,
        datumod:"",
        datumdo:"",
        kolicina:null,
        eventid:this.$route.params.id,
       },
       ticketedit:{
        pricechange:false,
        id:null,
        naslov:"",
        cijena:null,
        datumod:"",
        datumdo:"",
        kolicina:null,
        opis:"",
        eventid:this.$route.params.id,
       },

       editform:{
        id:0,
        naslov:"",
        opis:"",
        lokacija:"",
        datum:"",
        caption:"",
        brojkarta:0,
        rezstola:false,
        klubid:this.$route.params.klub,
        rezervacije:0,
        ticketchange:false,
        
       },
       slikaform:{
        id:0,
        rbr:1,
        vrsta:"img",
        klubid:this.$route.params.klub,
       },

       uploadslika:{
        id:0,
        rbr:0,
        slika:null,
        klubid:this.$route.params.klub
       },

       rezform:{
        stol:0,
        idstola:null,
        id:"",
        ime:"",
        telefon:"",
        email:"",
        edit:false,
        vrstarez:"",
        klubid:this.$route.params.klub,
 
       },

       reklamiranjeform:{
        id:0,
        vrsta:"",
       },
       stoldeleteform:{
        id:0,
        vrsta:"stol",
        klubid:this.$route.params.klub,
        eventid:this.$route.params.id,
       },
       porukashare:"",
       deleteform:{
      
            id:0,
            eventid:this.$route.params.id,
        },

        series: [44, 55, 67, 83],
          chartOptions: {
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    fontSize: '16px',
                  },
                  total: {
                    show: true,
                    label: 'Total Sale',
                    formatter: function (w) {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return 232
                    }
                  }
                }
              }
            },
            labels: [],
        },
    }
  },
  mounted(){





    window.$(".containerloading").show()
    this.id = this.$route.params.id;
    this.rezform.id = this.id
    this.editform.id = this.id
    this.uploadslika.id = this.id
    this.reklamiranjeform.id = this.id

    this.getEvent()


   


  },
  methods:{
    deletemodal(id){
        window.$("#removeticket").modal('show')
        this.deleteform.id = id

    },

    deleteticket(){
        console.log("deleting")
    
    axios.post('/deleteticket/', this.deleteform)
    .then((res) => {

        this.$root.toastnoti('Success', 'Ticket is deleted', 'uspjeh')

        window.$("#removeticket").modal('hide')

        this.event.tickets = this.event.tickets.filter(item => {
        if (item[5] === this.deleteform.id) {
          return false; 
        }
        return true
      });

     
        this.updated()


   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  

    editTicket(){
        if(this.ticketedit.cijena != this.ticketedit.staracijena){
            this.ticketedit.pricechange = true
        }
    
    axios.post('/tiketedit/', this.ticketedit)
    .then((res) => {


        let ticket = this.event.tickets.filter(i => i[5] === this.ticketedit.id);
        ticket[0][0] = this.ticketedit.naslov
        ticket[0][1] = this.ticketedit.datumod
        ticket[0][2] = this.ticketedit.datumdo
        ticket[0][3] = this.ticketedit.kolicina
        ticket[0][4] = this.ticketedit.cijena
        ticket[0][6] = this.ticketedit.opis
        this.ticketedit.pricechange = false
        this.ticketedit.staracijena = this.ticketedit.cijena
   


        window.$("#ticketedit").modal('hide')
        this.$root.toastnoti('Uspjeh', 'Ticket is edited', 'uspjeh')


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
}, 

    openticketedit(id){
        let ticket = this.event.tickets.filter(i => i[5] === id);
        ticket = ticket[0]
       
        this.ticketedit.staracijena = ticket[4]
        this.ticketedit.naslov = ticket[0]
        this.ticketedit.cijena = ticket[4]
        this.ticketedit.opis = ticket[6]
        this.ticketedit.kolicina = ticket[3]
        this.ticketedit.datumod = ticket[1]
        this.ticketedit.datumdo = ticket[2]
        this.ticketedit.id = id

    window.$("#ticketedit").modal('show')
  },
    updated() {
    // Destroy and reinitialize DataTable when data changes
    const dataTable = $('#myProjectTable').DataTable();
    dataTable.destroy();

    setTimeout(() => {
        this.initdatatable(true)
 
      }, 200);
    
  },


    createTicket(){
    
    axios.post('/tiketpost/', this.ticket)
    .then((res) => {


        this.event.tickets.push([this.ticket.naslov, this.ticket.datumod, this.ticket.datumdo, this.ticket.kolicina, this.ticket.cijena])
        this.updated()
        this.ticket = {
        naslov:"",
        cijena:null,
        datumod:"",
        datumdo:"",
        kolicina:null,
        eventid:this.$route.params.id,
       },



        window.$("#tickadd").modal('hide')
        this.$root.toastnoti('Uspjeh', 'Ticket is made', 'uspjeh')


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  
    
    openmodaladd(){
    window.$("#tickadd").modal('show')
  },

    togglekarte(){
        this.editform.karte = !this.editform.karte
    },

    async postinstagram() {
        window.$(".containerloading").show()
   

   await axios
     .get('/postinstagram/'+this.id)
     .then(res => {

        console.log(res.data)

        if(res.data[0] == 0){
            this.$root.toastnoti('Greška', res.data[1], 'greska')
        }
        else{
            window.$('#dremovetask').modal("hide")
            this.$root.toastnoti('Uspjeh!', 'Event je objavljen na instagramu', 'uspjeh')
            this.event.instagram = true
        }

        window.$(".containerloading").hide()
        
       

    
   
     })
     .catch(error => {
   
        this.$root.toastnoti('Greška', 'Greška, molimo kontaktirajte nas. ', 'uspjeh')
       window.$(".containerloading").hide()
   
     })
   
   },

    reklamirajpost(){

        if(this.reklamiranjeform.vrsta=="instagram"){
            this.postinstagram()
            return;
        }
    
    axios.post('/reklamiranje/', this.reklamiranjeform)
    .then((res) => {

        if(this.reklamiranjeform.vrsta=="objavi_web"){
            this.event.web = true
            this.$root.toastnoti('Uspjeh', 'Event je objavljen na stranici', 'uspjeh')
        }
        else if(this.reklamiranjeform.vrsta=="odjavi_web"){
            this.event.web = false
            this.$root.toastnoti('Uspjeh', 'Event više neće biti prikazan na web stranici', 'uspjeh')
        }
        else if(this.reklamiranjeform.vrsta=="objavi_email"){
            this.event.email = true
            this.$root.toastnoti('Uspjeh', 'Mail poslan svim klijentima', 'uspjeh')
        }
        else if(this.reklamiranjeform.vrsta=="objavi_emailpodsjetnik"){
            this.event.email = true
            this.event.podsjetnik+=1
            this.$root.toastnoti('Uspjeh', 'Mail podsjentik poslan svim klijentima', 'uspjeh')
        }
   
        window.$('#dremovetask').modal("hide")
        

        
   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


    handleFileChange(event) {
      this.uploadslika.slika = event.target.files[0];
    },

    openreklamiranjeform(vrsta, poruka){
  
        this.porukashare = poruka
        this.reklamiranjeform.vrsta = vrsta
        
        window.$('#dremovetask').modal("show")

    },

  
    
      
    uploadslikaform(){

        axios.post('/slikaupload/', this.uploadslika, {headers: {
        'Content-Type': 'multipart/form-data',
      },})
    .then((res) => {
        
        console.log(res.data)
        console.log(this.event)
        this.event.slike.push(res.data)
        window.$('#uploadslika').modal("hide")

        this.uploadslika.slika = null
        document.getElementById('formFileMultipleoneone').value= null;
        this.$root.toastnoti('Uspjeh', 'Slika je spremljena', 'uspjeh')
        
        console.log(this.event)
            
      })
    .catch((error) => {
      console.log(error)    
      this.$root.toastnoti('Greška!', 'Odaberi sliku', 'greska')
     
    })

},


    createrez(){

        axios.post('/stolpost/', this.rezform)
            .then((res) => {
                
                if(!this.rezform.edit){
                    window.$('#edittickit').modal("hide")
                    let filteredindex = this.event.stolovi.findIndex(item => item.stol == this.rezform.stol);
                    this.event.stolovi[filteredindex].lista[2] = false
                    this.event.stolovi[filteredindex].lista[3] = [this.rezform.stol, this.rezform.ime, this.rezform.telefon, this.rezform.email, this.rezform.vrstarez, res.data]
                    this.$root.toastnoti('Uspjeh', 'Rezervacija stola je napravljena', 'uspjeh')
                }
                else{
                    this.$root.toastnoti('Uspjeh', 'Podaci su spremljeni', 'uspjeh')
                    console.log(this.rezform)
                    let index = this.event.stolovi.findIndex(item => item.stol == this.rezform.stol);
         
        
                if (index != -1) {
                    this.event.stolovi[index].lista[3][1] = this.rezform.ime
                    this.event.stolovi[index][3][2] = this.rezform.telefon
                    this.event.stolovi[index][3][3] = this.rezform.email
                    this.event.stolovi[index][3][4] = this.rezform.vrstarez
            

                }

       
                }

                    
              })
            .catch((error) => {
              console.log(error)    
             
            })

    },

    openmodal(ime, telefon, email, edit, stolid, stol, vrstarez){
        this.rezform.ime = ime
        this.rezform.telefon = telefon
        this.rezform.email = email
        this.rezform.edit = edit
        this.rezform.idstola = stolid
        this.rezform.stol = stol
        this.rezform.vrstarez = vrstarez

        window.$('#edittickit').modal("show")

    },

    openslikamodal(id, rbr){
        console.log(id)
        this.slikaform.id = id
        this.slikaform.rbr = rbr
 

        window.$('#editslika').modal("show")

    },
    openslikaupload(){
        this.uploadslika.slika = null
        if (this.event.slike.length == 0){
            this.uploadslika.rbr = 1
        }
        else{
            this.uploadslika.rbr = parseInt(this.event.slike[this.event.slike.length-1][1], 10)+1

        }

        
        window.$('#uploadslika').modal("show")

    },

    deleteimg(){
        
    console.log(this.slikaform)
    axios.post('/deletepost/', this.slikaform)
    .then((res) => {
        this.$root.toastnoti('Uspjeh', 'Slika je izbrisana', 'uspjeh')

        this.event.slike = this.event.slike.filter(item => {
        if (item[2] === this.slikaform.id) {
          return false; 
        }
        return true
      });

        window.$("#editslika").modal('hide')

            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  
deleterez(){

    this.stoldeleteform.id = this.rezform.idstola
    axios.post('/deletepost/', this.stoldeleteform)
    .then((res) => {
        console.log(this.rezform.stol)
        this.$root.toastnoti('Uspjeh', 'Rezervacija je izbrisana', 'uspjeh')
        window.$('#edittickit').modal("hide")
        let filteredindex = this.event.stolovi.findIndex(item => item.stol == this.rezform.stol);
        
        this.event.stolovi[filteredindex].lista[2] = true

        window.$("#editslika").modal('hide')

            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  
changerednibroj(){
    
    axios.post('/changerednibroj/', this.slikaform)
    .then((res) => {
        this.$root.toastnoti('Uspjeh', 'Promijenjen je redni broj slike', 'uspjeh')
        console.log(this.slikaform.id)
        const index = this.event.slike.findIndex(item => item[2] === this.slikaform.id);
        console.log(this.event.slike[index])
        
        if (index !== -1) {
    
        this.event.slike[index][1] = this.slikaform.rbr
        }
        this.event.slike = this.event.slike.slice().sort((a, b) => a[1] - b[1]);
        window.$("#editslika").modal('hide')

            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  




editEvent(){
    if(this.event.cijena != this.editform.cijena){
        this.editform.ticketchange = true
    }
 
            axios.post('/editpost/', this.editform)
            .then((res) => {
                this.$root.toastnoti('Uspjeh', 'Podaci su spremljeni', 'uspjeh')

                this.editform.ticketchange = false
                this.event.cijena = this.editform.cijena
           


                    
              })
            .catch((error) => {
                this.editform.ticketchange = false    
             
            })
        },  
        initdatatable(newvar){
        $('#myProjectTable')
        .addClass( 'nowrap' )
        .dataTable( {
            responsive: true,
            columnDefs: [
                { targets: [-1, -3], className: 'dt-body-right' }
            ]
        });

        if(newvar){
            window.$(".containerloading").hide()
        }

        

    },


    async getEvent() {
   

   await axios
     .get('/eventidetalji/'+this.id+'/'+this.$route.params.klub)
     .then(res => {
       this.event = res.data
       this.event.stolovi.sort((a, b) => a.stol - b.stol);
       console.log(this.event)

       this.editform.lokacija = this.event.lokacija
       this.editform.naslov = this.event.naslov
       this.editform.cijena = this.event.cijena
       this.editform.opis = this.event.opis
       this.editform.datum = this.event.datum
       this.editform.karte = this.event.karte
       this.editform.caption = this.event.caption
       this.editform.brojkarta = this.event.brojkarta
       this.editform.rezervacije = this.event.rezervacije

       this.updated()

       this.chartOptions.labels = this.event.tickets.map(item => item[0]);
       this.series = this.event.tickets.map(item => item[item.length - 1]);
       let sum = 0;

        for (let i = 0; i < this.series.length; i++) {
            
            sum += this.series[i];
         
        }
        for (let i = 0; i < this.series.length; i++) {
            
           
            this.series[i] = Math.round((this.series[i] / sum) * 100);
        }

       
       this.chartOptions = {
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    fontSize: '16px',
                  },
                  total: {
                    show: true,
                    label: 'Total Sale',
                    formatter: function () {
                 
                  return sum
                },
                  }
                }
              }
            },
            labels : this.event.tickets.map(item => item[0]),
        },


       window.$(".containerloading").hide()


    
   
   
     })
     .catch(error => {
        console.log(error)
        if(error.response && error.response.status == 401){
            this.$router.push('/login/')
                }
   
     })
   
   },
    
  },
}
</script>

<style>
.rezervirano{
    background-color:rgba(255, 0, 0, 0.5);
}
.slobodno{
    background-color:rgba(40, 167, 69, 0.5);
}
.alert-fixed {
    position:fixed; 
    top: 0px; 
    left: 0px; 
    width: 100%;
    z-index:9999; 
    border-radius:0px
}

.circle-image {
  width: 50px; /* Adjust the width and height to your desired size */
  height: 50px;
  border-radius: 50%; /* Makes it a circle */
  overflow: hidden; /* Clips the image to the circle */
  display: inline-block; /* To maintain inline alignment */
}

.small-image {
  width: 100%; /* Make the image fill the circle */
  height: auto; /* Maintain the aspect ratio of the image */
}
</style>



<style>

.containerloading {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);

}
.dots {
  position: relative;
  width: 150px;
  height: 50px;
  transform: scale(0.25, 0.25);
}
.dot {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  border-radius: 50%;
  background-color: var(--primary-color);
}
.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}
.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}
.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}
@-moz-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@-webkit-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@-o-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}

</style>