<template>


    <div id="mytask-layout" class="theme-indigo">
    
    <!-- sidebar -->
    <div class="sidebar px-4 py-4 py-md-5 me-0">
        <div class="d-flex flex-column h-100">
            <a  class="mb-0 brand-icon">
                <span class="logo-icon">
                   <!--
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon-fill" viewBox="0 0 16 16">
  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
</svg>-->
<img :src="this.$store.state.logo" style="width:40px;height: auto;" >
                </span>
                <span class="logo-text">NightWise <small style="color:lightgray;">{{$store.state.ime}}</small></span>
            </a>
            <!-- Menu: main ul -->
            <ul class="menu-list flex-grow-1 mt-3">
                <li v-if="this.permissions.eventi"><a  class="m-link" @click="$router.push('/klub/'+this.$route.params.klub + '/')"><i class="icofont-info"></i> <span>Events</span></a></li>
                
                <li v-if="this.permissions.klijenti"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/clients/')"><i class="icofont-user"></i> <span>Clients</span></a></li>
                <li v-if="this.permissions.narudzbe"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/orders/')"><i class="fa fa-wine-bottle"></i> <span>Drink Orders</span></a></li>
                <li v-if="this.permissions.glazba"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/musicorders/')"><i class="fa fa-music"></i> <span>Music Orders</span></a></li>
    
              
                <li v-if="this.permissions.meni"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/menu/')" style="cursor: pointer;"><i class="fa fa-martini-glass"></i> <span>Menu</span></a></li>
                <li v-if="this.permissions.loyalty"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/loyalty/')" style="cursor: pointer;"><i class="fa fa-coins"></i> <span>Loyalty</span></a></li>
                <li v-if="this.permissions.plan"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/plan/')" style="cursor: pointer;"><i class="fa fa-table-cells-large"></i> <span>Layout</span></a></li>

                <li v-if="this.permissions.korisnici"><a  class="m-link" @click="$router.push('/'+this.$route.params.klub + '/korisnici/')"><i class="fa fa-user"></i> <span>Staff</span></a></li>
              
    
                <li v-if="this.permissions.ulaz"><a  class="m-link" @click="this.openticketform()" style="cursor: pointer;"><i class="fa fa-ticket"></i> <span>Ticket Scanner</span></a></li>
                <li v-if="this.permissions.info"><a  class="m-link" @click="this.openinfoform()" style="cursor: pointer;"><i class="fa fa-info"></i> <span>Info Editor</span></a></li>

                
                <li v-if="!this.$store.state.username"><a  class="m-link" @click="$router.push('/')"><i class="fa fa-sign-in"></i> <span>Log in</span></a></li>
    <br>        
                <li ><a style="color:var(--secondary-color);opacity: 0.6;cursor: pointer;" class="m-link" @click="logout"><i class="icofont-logout"></i> <span>Log out</span></a></li>
               
                
    
            </ul>
    
            <!-- Theme: Switch Theme -->
            <ul class="list-unstyled mb-0">
              
                
          
            </ul>
            <!-- Menu: menu collepce btn -->
            <button type="button" class="btn btn-link sidebar-mini-btn text-light">
                <span class="ms-2"><i class="fa fa-arrow-left"></i></span>
            </button>
        </div>
    </div>
    
    
        <div class="main px-lg-4 px-md-4">
    
    <!-- Body: Header -->
    <div class="header">
        <nav class="navbar py-4">
            <div class="container-xxl">
    
                <!-- header rightbar icon -->
                <div class="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
           
                  
                    <div style="z-index: 0;" class="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                        <div class="u-info me-2">
                            <p class="mb-0 text-end line-height-sm "><span class="font-weight-bold">{{ this.$store.state.username }}</span></p>
                            <small>{{this.$store.state.uloga}}</small>
                        </div>
                        <a class="nav-link dropdown-toggle pulse p-0" href="#" role="button">
                            <img  class="avatar lg rounded-circle img-thumbnail" src="../assets/images/user.png" alt="profile">
                        </a>
        
                    </div>
                </div>
    
                <!-- menu toggler -->
                <button class="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" data-bs-toggle="collapse" data-bs-target="#mainHeader">
                    <span class="fa fa-bars"></span>
                </button>
    
                <!-- main menu Search-->
                <div class="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
                    <div class="input-group flex-nowrap input-group-lg">
                        <button type="button" class="input-group-text" id="addon-wrapping"><i class="fa fa-search"></i></button>
                        <input type="search" class="form-control" placeholder="Search" aria-label="search" aria-describedby="addon-wrapping">
    
                    </div>
                </div>
    
            </div>
        </nav>
    </div>

    <slot name="body">

    </slot>

    <div class="modal fade" id="bodoviform" tabindex="-1"  aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Bodovi</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body justify-content-center flex-column d-flex">
                        
    <StreamBarcodeReader
    style="z-index: 999999;"
    v-if="skeniraj"
        @decode="onDecode"
        @loaded="onLoaded"
    ></StreamBarcodeReader>
                        <i class="fa fa-coins text-danger display-2 text-center mt-1" style="color:var(--primary-color) !important"></i><br>
                     
                        <button class="btn btn-success" style="color:white;" @click="toggleskener()" v-if="!skeniraj">Skeniraj Qr kod</button><br>
                        <button class="btn btn-danger" style="color:white;" @click="toggleskener()" v-if="skeniraj">Ugasi Skener</button><br>

                        <button class="btn btn-info" style="color:white;background:var(--primary-color) !important" v-show="dodaj" @click="toggleVariable()" > Dodaj Bodove <i class="btn btn-plus"></i></button>
                        <button class="btn btn-danger" style="color:white;" v-show="!dodaj" @click="toggleVariable()"> Skini Bodove <i class="btn btn-minus"></i></button><br>
                        <input type="number" class="form-control" placeholder="Trošak u eurima" v-show="dodaj" v-model="dodajform.novac">
                        <input type="number" class="form-control" placeholder="Koliko bodova" v-show="!dodaj" v-model="oduzmiform.bodovi">
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success color-fff" style="color:White;" @click="dodajbodove()" v-if="dodaj">Potvrdi</button>
                        <button type="button" class="btn btn-success color-fff" style="color:White;" @click="oduzmibodove()" v-if="!dodaj">Potvrdi</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                        
                    </div>
                </div>
                </div>
            </div>

  
    
        
    <div class="modal fade" id="ticketform" tabindex="-1"  aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Ulaz</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body justify-content-center flex-column d-flex">
                        
    <StreamBarcodeReader
    style="z-index: 999999;"
    v-if="skenirajulaz"
        @decode="DecodeTicket"

    ></StreamBarcodeReader>
                        <i class="fa fa-ticket text-danger display-2 text-center mt-1"></i><br>
                     
                        <button class="btn btn-success" style="color:white;" @click="toggleskenerulaz()">Skeniraj Qr kod</button><br>
              
                     
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                        
                    </div>
                </div>
                </div>
            </div>

        

            <div class="modal fade" id="editklub" tabindex="-1"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title  fw-bold" id="leaveaddLabel">Izmijeni informacije o klubu</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="editklub()">
            <div class="modal-body">
           
                <div class="row g-3 mb-3">
                          <div class="col">
                            <label for="sub" class="form-label">Email</label>
                    <input type="text" class="form-control"  id="sub" v-model="editform.email" required>
                       </div>

                    

                          <div class="col">
                            <label for="sub" class="form-label">Telefon</label>
                    <input type="text" class="form-control"  id="sub" v-model="editform.telefon"  required>
                          </div>
                        </div>

                <div class="row g-3 mb-3">
                          <div class="col">
                            <label for="sub" class="form-label">Instagram</label>
                    <input type="text" class="form-control"  id="sub" v-model="editform.instagram"  required>
                       </div>

                    

                          <div class="col">
                            <label for="deptwo" class="form-label">Facebook</label>
                            <input type="text" class="form-control"  id="deptwo" v-model="editform.facebook"  required>
                          </div>
                        </div>
     
                <div class="mb-3">
                        <label for="exampleFormControlTextarea78" class="form-label">O nama (about page)</label>
                        <textarea class="form-control" id="exampleFormControlTextarea78" v-model="editform.onama"   rows="7" placeholder="Više informacije o eventu" required></textarea>
                    </div>

                
            </div>
            <div class="modal-footer">

                <button type="submit" class="btn btn-primary">Uredi</button>
            </div>
        </form>
        </div>
        </div>
    </div>

    
           
    </div>
    </div>
    
    </template>

  
<script>

import axios from 'axios';
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: 'Navigation',
  components: {
    StreamBarcodeReader,


  },

  data(){
    return{
        email:"",
        permissions:[],
            skeniraj:false,
            skenirajulaz:false,
            dodaj:true,
            dodajform:{
                dodaj:true,
                novac:"",
                email:"",
                klubid:this.$route.params.klub

            },
            oduzmiform:{
                dodaj:false,
                bodovi:"",
                email:"",
                klubid:this.$route.params.klub
            },
            editform:{
                email:"",
                telefon:"",
                instagram:"",
                facebook:"",
                onama:"",
                klubid:null,

            }
    }
  },
  mounted(){
    this.editform.klubid = this.$route.params.klub
    this.getpermissions()

    this.$root.getKlub(this.$route.params.klub)

    // main sidebar toggle js
    $('.menu-toggle').on('click', function () {
        $('.sidebar').toggleClass('open');
        $('.open').removeClass('sidebar-mini');
    });

    // layout a sidebar mini version
    $('.sidebar-mini-btn').on('click', function () {
        $('.sidebar').toggleClass('sidebar-mini');
        $('.sidebar-mini').removeClass('open');
    });

    // chat page chatlist toggle js
    $('.chatlist-toggle').on('click', function () {
        $('.card-chat').toggleClass('open');
    });

    $(".theme-rtl input").on('change',function() {
        if(this.checked) {
            $("body").addClass('rtl_mode');
        }else{
            $("body").removeClass('rtl_mode');
        }
       
    });



    // Dropdown scroll hide using table responsive
    
    $('.table-responsive').on('show.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "inherit" );
    });
   
    $('.table-responsive').on('hide.bs.dropdown', function () {
            $('.table-responsive').css( "overflow", "auto" );
    })

    // light and dark theme setting js
    var toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
    var toggleHcSwitch = document.querySelector('.theme-high-contrast input[type="checkbox"]');
    var currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);
    
        if (currentTheme === 'dark') {
            toggleSwitch.checked = true;
        }
        if (currentTheme === 'high-contrast') {
            toggleHcSwitch.checked = true;
            toggleSwitch.checked = false;
        }
    }
    


    


  },


  methods:{

    async getpermissions() {
    
    await axios
      .get('/staff/mypermissions/')
      .then(res => {

        this.permissions = res.data
        console.log(this.permissions)
    
      
      })
      .catch(error => {
        this.$root.toastnoti('Greška', 'Greška pri učitavanju stranice', 'greska')
     
      })
    
    },
    

    editklub(){
        window.$(".containerloading").show()
    axios.post('/editklub/', this.editform)
    .then((res) => {
        this.$root.toastnoti('Uspjeh', 'Podaci su spremljeni', 'uspjeh')
   
        window.$(".containerloading").hide()

        
   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


    openinfoform(){
        window.$(".containerloading").show()
         axios
         .get('/getklubstaff/'+this.$route.params.klub)
     .then(res => {
       this.editform.instagram = res.data.instagram
       this.editform.facebook = res.data.facebook
       this.editform.telefon = res.data.telefon
       this.editform.email = res.data.email
       this.editform.onama = res.data.onama

       window.$("#editklub").modal('show')
       window.$(".containerloading").hide()
   
   
     })
     .catch(error => {
        this.$root.toastnoti('Greška', "Greška prilikom dohvaćanja podataka", 'greska')
   
     })

            
        },


    DecodeTicket(text){
        this.skenirajulaz = false
        axios
     .get('/klub/provjeriticket/'+text+'/'+this.$route.params.klub)
     .then(res => {



        this.$root.toastnoti('Uspjeh', "Kod je validan", 'uspjeh')

       
   
   
     })
     .catch(error => {
   
      if(error.response.status == 400){
        this.$root.toastnoti('Greška', "Kod je već iskorišten", 'reska')
                }
   
     })

        
    },
    dodajbodove(){
            this.dodajform.email = this.email
    
    axios.post('/loyalty/staffbodovi/', this.dodajform)
    .then((res) => {
        this.email = ""
        this.oduzmiform.bodovi = null
        this.dodajform.novac = null
        this.$root.toastnoti('Uspjeh', 'Bodovi su dodani', 'uspjeh')


            
      })
    .catch((error) => {
        this.$root.toastnoti("Greška", error.response.data, "greska")
     
    })
},  

oduzmibodove(){
            this.oduzmiform.email = this.email
    
    axios.post('/loyalty/staffbodovi/', this.oduzmiform)
    .then((res) => {
        this.email = ""
        this.oduzmiform.bodovi = null
        this.dodajform.novac = null
    
        this.$root.toastnoti('Uspjeh', 'Bodovi su oduzeti', 'uspjeh')


            
      })
    .catch((error) => {
        this.$root.toastnoti("Greška", error.response.data, "greska") 
     
    })
},  

        
        toggleVariable() {
          this.dodaj = !this.dodaj;
        },
        toggleskener() {
          this.skeniraj = !this.skeniraj;
        },
        toggleskenerulaz() {
          this.skenirajulaz = !this.skenirajulaz;
        },
        
    
        onDecode(text) {
            this.skeniraj=false

            
            const regex = /^(\d+)\/(.+)$/;

            const match = text.match(regex);

            if (match) {
            const numbers = match[1];
            if(match[1] > 0){
                this.oduzmiform.bodovi = match[1]
                this.dodaj = false
            }
            else{
                this.dodaj = true
            }

            this.email = match[2];
 
            } 
    
    },
    
        openbodoviform(){
            window.$("#bodoviform").modal('show')
        },
        openticketform(){
            window.$("#ticketform").modal('show')
        },
        
    
        deletemodal(id){
            window.$("#dremovetask").modal('show')
            this.deleteform.id = id
    
        },


     overFlowDynamic(){ 
        var sideheight=$(".sidebar.sidebar-mini").height() + 48;
        
        if(sideheight <= 760) {  
            $(".sidebar.sidebar-mini").css( "overflow", "scroll");  
        }
        else{
            $(".sidebar.sidebar-mini").css( "overflow", "visible"); 
        }
    },

    async logout() {


  await axios
        .post("/klub/logout/", {token : localStorage.getItem('token')})
        .then(response => {
          
          
        this.$root.toastnoti("Uspjeh", "Odjavljeni ste", "greska")
        })
        .catch(error => {
            this.$root.toastnoti("Uspjeh", "Odjavljeni ste", "greska")

        })
        axios.defaults.headers.common["Authorization"] = ""
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("uloga")

        this.$router.push("/login/");
        


    
},
    
}
}
</script>

<style>
.m-link{
    cursor: pointer;
}
</style>