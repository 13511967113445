<template>
    <Navigation>
        <template #body>
            <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Loyalty Offers</h3>
                        <div class="col-auto d-flex w-sm-100">
                            <button type="button" class="btn btn-dark btn-set-task w-sm-100" @click="createform()"><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj Ponudu</button>
                        </div>
                    </div>
                </div>
            </div> <!-- Row end  -->
            <div class="row clearfix g-3">
              <div class="col-sm-12">
                    <div class="card mb-3">
                        <div class="card-body">

                    
                           
                            <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Action</th> 
                                        <th>Name</th>
                                        <th>Discount</th>
                                        <th>Quantity</th>
                                        <th>Points</th>
                                       
                                    
                                   
                                    </tr>
                                </thead>
                                <tbody>
                                  
                                
                                     <tr v-for="i in ponude">
                                        
                                        <td>
                                             <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <a style="padding-right: 15px;"><button type="button" class="btn btn-outline-secondary" @click="openticketedit(i[2])" ><i class="icofont-info text-success"></i></button></a>
                                                 <button type="button" class="btn btn-outline-secondary" @click="deletemodal(i[2])"><i class="icofont-ui-delete text-danger"></i></button>
                                             </div>
                                         </td>

                                   
                                        <td>
                                            <i class="fa fa-check" v-if="i[8]" style="color:lightgreen;"></i>
                                            <i class="fa fa-times" v-if="!i[8]" style="color:red;"></i>&nbsp;
                                            <span v-if="i[4]">{{i[5]}}</span>
                                            <span v-else>{{ i[6] }}</span>
                                            
                                          </td>
                                          <td>
                                            {{i[3]}}%
                                          </td>
                                          <td>
                                            {{i[7]}}
                                          </td>

                                        <td>
                                       
                                            <span class="fw-bold ms-1">{{i[1]}}</span>
                                        </td>
                                    
                                        
                                       
                                     </tr>
                                   
                                </tbody>
                            </table>
                     
                        </div>
                    </div>
              </div>
            </div><!-- Row End -->
        </div>
    </div>
     
    
            <div class="modal fade" id="uploadslika" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Kreiraj Ponudu</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="createPonuda()">
                <div class="modal-body">
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Type of Reward</label>
                        <select class="form-select" v-model="form.type">
 
                        <option value="drink">Drink</option>
                        <option value="ticket">Ticket</option>
               
                        </select>
                    </div>
                    <div class="mb-3" v-if="this.form.type=='drink'">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Select Drink</label>
                        <select class="form-select" v-model="form.drink">
            
                            <option :value="i[0]" v-for="i in meni">{{ i[1] }}</option>
               
                        </select>
                    </div>
                    <div class="mb-3" v-if="this.form.type=='ticket'">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Select Ticket</label>
                        <select class="form-select" v-model="form.ticket">
                            <option :value="i[0]" v-for="i in tickets">{{i[2]}} {{ i[1] }}</option>
                 
               
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Discount in percentage</label>
                        <input type="number" class="form-control" id="sub1" v-model="form.discount" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Price in points</label>
                        <input type="number" class="form-control" id="sub1" v-model="form.bodovi" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Quantity</label>
                        <input type="number" class="form-control" id="sub1" v-model="form.quantity" required>
                    </div>
       
                   
                
                </div>
           
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>

                    <button type="submit" class="btn btn-success" style="color:white">Spremi</button>
                </div>
            </form>
            </div>
            </div>
        </div>
        <div class="modal fade" id="editform" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Edit Offer</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="editPonuda()">
                <div class="modal-body">
                
        
                    <div class="mb-3" v-if="this.editform.drink">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Drink</label>
                        <input type="text" class="form-control" id="sub1" :value="editform.drink" disabled>
                    </div>
                    <div class="mb-3" v-if="this.editform.ticket">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Ticket</label>

                        <input type="text" class="form-control" id="sub1" :value="editform.ticket" disabled>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Discount in percentage</label>
                        <input type="number" class="form-control" id="sub1" v-model="editform.discount" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Price in points</label>
                        <input type="number" class="form-control" id="sub1" v-model="editform.bodovi" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Quantity</label>
                        <input type="number" class="form-control" id="sub1" v-model="editform.quantity" required>
                    </div>
                    <div class="form-check form-switch">
       
  <input class="form-check-input" type="checkbox" role="switch" v-model="editform.aktivno">

</div>
       
                   
                
                </div>
           
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>

                    <button type="submit" class="btn btn-success" style="color:white">Spremi</button>
                </div>
            </form>
            </div>
            </div>
        </div>
        
        <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Izbriši ponudu?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deletemenu()">Izbriši</button>
                </div>
            </div>
            </div>
        </div>



            
        </template>
    </Navigation>
    
            <!-- Body: Body -->
          
    
        
        </template>
        
        
        <script>
        import '../assets/bundles/dataTables.bundle.js';
        import '../assets/bundles/libscripts.bundle.js';


        import $ from 'jquery'
    
        import Navigation from '../components/Navigation.vue'
        import axios from 'axios'
        export default {
          name: 'EventiView',
        
        
          data(){
            return{
            ponude:null,
            tickets:[],
            meni:[],
            form:{
                type:"drink",
                quantity:null,
                discount:null,
                drink:null,
                ticket:null,
                bodovi:null,
                aktivno:null,
                klubid:this.$route.params.klub,
            },
            editform:{
                id:null,
                type:"",
                quantity:null,
                discount:null,
                drink:null,
                ticket:null,
                bodovi:null,
                aktivno:null,
                klubid:this.$route.params.klub,
                drinkid:null,
                ticketid:null,
            },
            deleteform:{
            vrsta:"loyalty",
            id:0,
            klubid:this.$route.params.klub,
        },

          }
        },
        components: {
        Navigation
      },
    
          mounted(){
            $(".containerloading").show()
            this.initdatatable()
            
            this.getPonude()
        
        
          },
          methods:{
            editPonuda(){
    
    axios.post('/klub/editponuda/', this.editform)
    .then((res) => {
        const filteredList = this.ponude.filter(i => i[2] === this.editform.id);
        filteredList[0][1] = this.editform.bodovi
        filteredList[0][3] = this.editform.discount
        filteredList[0][7] = this.editform.quantity
        filteredList[0][8] = this.editform.aktivno
        this.editform = {
                type:"",
                id:null,
                quantity:null,
                discount:null,
                drink:null,
                ticket:null,
                bodovi:null,
                aktivno:null,
                klubid:this.$route.params.klub,
            },

        this.updated()
        window.$("#editform").modal('hide')
        this.$root.toastnoti('Success', 'Offer is edited', 'uspjeh')


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            openticketedit(id){
                this.editform.id = id
                const filteredList = this.ponude.filter(i => i[2] === id);
      
                let list;
                
                list = filteredList[0]
                console.log(list)
                this.editform.discount = list[3]
                this.editform.quantity = list[7]
                this.editform.bodovi = list[1]
                this.editform.drink = list[5]
                this.editform.ticket = list[6]
                this.editform.aktivno = list[8]
                this.editform.type = list[4]
                this.editform.drinkid = list[10]
                this.editform.ticketid = list[9]
      

                window.$("#editform").modal('show')
            },


            initdatatable(newvar){
        $('#myProjectTable')
        .addClass( 'nowrap' )
        .dataTable( {
            responsive: true,
            columnDefs: [
                { targets: [-1, -3], className: 'dt-body-right' }
            ]
        });

        if(newvar){
            window.$(".containerloading").hide()
        }

        

    },

    updated() {
    // Destroy and reinitialize DataTable when data changes
    const dataTable = $('#myProjectTable').DataTable();
    dataTable.destroy();

    setTimeout(() => {
        this.initdatatable(true)
 
      }, 200);
    
  },




            createPonuda(){
    
    axios.post('/klub/postponuda/', this.form)
    .then((res) => {
        let drinktype;
        let drinknaslov;
        let ticketnaslov;
        if(this.form.type == "drink"){
            drinktype = true
            const filteredList = this.meni.filter(innerList => innerList[0] === this.form.drink);
            console.log(filteredList)
            drinknaslov = filteredList[0][1]

        }
        else{
            drinktype = false
            const filteredList = this.tickets.filter(innerList => innerList[0] === this.form.ticket);
            ticketnaslov = filteredList[0][1]
            
        }
        console.log(drinktype)
        console.log(drinknaslov)
        this.ponude.push(["", this.form.bodovi, res.data.id, this.form.discount, drinktype, drinknaslov, ticketnaslov, this.form.quantity, true ])
        this.form.bodovi = null
        this.form.naslov = ""
 
        this.form.discount = null
        
        this.updated()

        
        window.$("#uploadslika").modal('hide')
        this.$root.toastnoti('Uspjeh', 'Proizvod je dodan na meni', 'uspjeh')



            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            deletemenu(){
    console.log(this.deleteform)
    axios.post('/deletepost/', this.deleteform)
    .then((res) => {
        this.$root.toastnoti('Success', 'Reward is removed', 'uspjeh')
        window.$("#dremovetask").modal('hide')

        
        this.ponude = this.ponude.filter(item => {
        if (item[2] === this.deleteform.id) {
          return false; 
        }
        
        return true
      });

      this.updated()
            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            deletemodal(id){
                this.deleteform.id = id
        window.$("#dremovetask").modal('show')


    },
    createform(){
        window.$("#uploadslika").modal('show')


    },
            handleFileChange(event) {
      this.form.img = event.target.files[0];
    },

            async getPonude() {
    
    await axios
      .get('/klub/ponude/'+this.$route.params.klub)
      .then(res => {

       this.ponude = res.data.ponude
       this.meni = res.data.meni
       this.tickets = res.data.tickets
        console.log(this.ponude)
       this.updated()

    
      $(".containerloading").hide()
        
    
      
      })
      .catch(error => {
        if(error.response.status == 401){
            this.$router.push('/login/')
                    }
        this.$root.toastnoti('Greška', 'Greška pri dohvaćanja narudžba', 'greska')
        $(".containerloading").hide()
      })
    
    },
    
          
          
            
          },
        }
        </script>
        
    