<template>
<Navigation>
    <template #body>
        <!-- Body: Body -->       
        <div class="body d-flex py-lg-3 py-md-2">
            <div class="container-xxl">
                <div class="row align-items-center">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold text-center">Klijenti</h3><br><br>
                            <p><br>Osobama na listi možete slati automatske obavijesti o eventu.</p>
                            <div class="col-auto d-flex w-sm-100">
                                <button type="button" class="btn btn-dark btn-set-task w-sm-100"  @click="openmodal(' ', ' ', ' ', false, -1, false)"><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj Klijenta</button>
                            </div>
                        </div>
                    </div>
                </div> <!-- Row end  -->
                <div class="row clearfix g-3">
                  <div class="col-sm-12">
                        <div class="card mb-3">
                            <div class="card-body">
                                <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Actions</th>
                                            <th>Email</th>
                                            <th>Ime i Prezime</th>
                                              
                                            
                                            <th>Telefon</th> 
                           
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                      
                                    
                             

                                         <tr v-for="i in klijenti">
                                            <td>
                                                 <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                    <button type="button" class="btn btn-outline-secondary" @click="openmodal(i[0], i[1], i[2], true, i[3], i[4])" ><i class="icofont-edit text-success" ></i></button>
                                                     <button type="button" class="btn btn-outline-secondary" @click="izbrisimodal(i[3])"><i class="icofont-ui-delete text-danger"></i></button>
                                                 </div>
                                             </td>

                                            <td>
                                                <span class="fw-bold ms-1" v-if="i[1]"> <i class="fa fa-star" style="color:orange" v-if="i[4]"> </i> {{ i[1] }}</span>
                                                    <span class="fw-bold ms-1" v-else>Prazno</span>
                                            </td>
                                            
                                            <td>
                                                
                                                <span class="fw-bold ms-1" v-if="i[0]">{{ i[0] }}</span>
                                                    <span class="fw-bold ms-1" v-else>Prazno</span>
                                              </td>
                                            <td>
                                           
                                                <span class="fw-bold ms-1" v-if="i[2]"> {{i[2]}}</span>
                                                <span class="fw-bold ms-1" v-else> Prazno</span>
                                            </td>
                                
                                          
                                           
                                            
                                         </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                  </div>
                </div><!-- Row End -->
            </div>
        </div>
        
    
    

    
         <!-- Edit Tickit-->
         <div class="modal fade" id="klijentadd" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    
                    <h5 class="modal-title  fw-bold" id="leaveaddLabel" v-show="!klijentform.uredi">Dodaj novog klijenta</h5>
                    <h5 class="modal-title  fw-bold" id="leaveaddLabel" v-show="klijentform.uredi">Uredi podatke o klijentu</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="sub" class="form-label">Ime i prezime </label>
                        
                        <input type="text" class="form-control" id="sub" v-model="klijentform.ime">
                    </div>
                    <label for="exampleFormControlTextarea78" class="form-label">Email</label>
                    <input type="text" class="form-control" id="sub"  v-model="klijentform.email">
                   <br>
                    <div class="mb-3">
                            <label for="exampleFormControlTextarea78" class="form-label">Telefon</label>
                            <input type="text" class="form-control" id="sub"  v-model="klijentform.telefon">
                        </div>

                        <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.klijentform.obavijesti">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Primanje Obavijesti</label>
</div>
                       </div>

                    
    
                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Otkaži</button>
                    <button type="submit" class="btn btn-primary" @click="submitklijent()">Spremi</button>
                </div>
            </div>
            </div>
        </div>
    


        <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Izbriši osobu sa liste?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deleteklijent()">Izbriši</button>
                </div>
            </div>
            </div>
        </div>


        </template></Navigation>

    
    </template>
    
    
<script>
import $ from 'jquery'
import '../assets/bundles/dataTables.bundle.js';
import '../assets/bundles/libscripts.bundle.js';
import Navigation from '../components/Navigation.vue'
import axios from 'axios'

export default {
  name: 'EventiView',
  components: {
    Navigation
  },

  data(){
    return{
        klijentform:{
            idklijenta:-1,
            ime:"",
            email:"",
            telefon:"",
            uredi:false,
            obavijesti:false,
            klubid:this.$route.params.klub

        },

        klijentdeleteform:{
            vrsta:"klijent",
            id:0,
            klubid:this.$route.params.klub

        },

        klijenti:[],
    }
  },

  methods:{

    submitklijent(){

axios.post('/klijentpost/', this.klijentform)
    .then((res) => {
        
      
        
        window.$("#klijentadd").modal('hide')
     
        if(!this.klijentform.uredi){
        
            this.klijenti.push([this.klijentform.ime, this.klijentform.email, this.klijentform.telefon, res.data, this.klijentform.obavijesti])
            this.updated()
            this.$root.toastnoti('Uspjeh', 'Klijent je dodan na listu', 'uspjeh')
        }
        else{
            const index = this.klijenti.findIndex(item => item[3] === this.klijentform.id);
   
        
        if (index !== -1) {
    
        this.klijenti[index][0] = this.klijentform.ime
        this.klijenti[index][1] = this.klijentform.email
        this.klijenti[index][2] = this.klijentform.telefon
        this.klijenti[index][4] = this.klijentform.obavijesti


        this.$root.toastnoti('Uspjeh', 'Podaci o klijentu su izmijenjeni', 'uspjeh')
        }
        }

            
      })
    .catch((error) => {
      console.log(error)    
     
    })

},

    initdatatable(newvar){
        $('#myProjectTable')
        .addClass( 'nowrap' )
        .dataTable( {
            responsive: true,
            columnDefs: [
                { targets: [-1, -3], className: 'dt-body-right' }
            ]
        });

        if(newvar){
            window.$(".containerloading").hide()
        }

    },


    async getKlijenti() {
   

   await axios
     .get('/klijenti/'+this.$route.params.klub)
     .then(res => {
       this.klijenti = res.data.klijenti
       console.log(this.klijenti)
       this.updated()
      

    

       
   
   
     })
     .catch(error => {
   
        if(error.response.status == 401){
            this.$router.push('/login/')
                }
   
     })
   
   },

   izbrisimodal(id){
    this.klijentdeleteform.id = id
    window.$("#dremovetask").modal('show')

   },


    openmodal(ime, email, telefon, uredi, id, obavijesti){

        this.klijentform.id = id
        this.klijentform.ime = ime
        this.klijentform.email = email
        this.klijentform.telefon = telefon
        this.klijentform.uredi = uredi
        this.klijentform.obavijesti = obavijesti

        window.$("#klijentadd").modal('show')

    },
    updated() {
    // Destroy and reinitialize DataTable when data changes
    const dataTable = $('#myProjectTable').DataTable();
    dataTable.destroy();

    setTimeout(() => {
        this.initdatatable(true)
      }, 200);
    
  },

  deleteklijent(){
    
    axios.post('/deletepost/', this.klijentdeleteform)
    .then((res) => {
        this.$root.toastnoti('Uspjeh', 'Klijent je izbrisana', 'uspjeh')

        this.klijenti = this.klijenti.filter(item => {
        if (item[3] === this.klijentdeleteform.id) {
          return false; 
        }
        return true
      });

      this.updated()

        window.$("#dremovetask").modal('hide')

            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  

  },
  mounted(){
    window.$(".containerloading").show()
    this.getKlijenti()
    
      

  }
}
</script>


<style scoped>

#flexSwitchCheckDefault{
    width:3.5em;height: 2em;
}

.userlabel{
    font-size: 15px;float:left;padding-left: 10px;padding-top: 3px;
}
</style>