<template>
    <Navigation>
        <template #body>
            <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Staff</h3>
                        <div class="col-auto d-flex w-sm-100">
                            <button type="button" class="btn btn-dark btn-set-task w-sm-100" @click="createform()"><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj korisnika</button>
                        </div>
                    </div>
                </div>
            </div> <!-- Row end  -->
            <div class="row clearfix g-3">
              <div class="col-sm-12">
                    <div class="card mb-3">
                        <div class="card-body">

                    
                           
                            <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>


                                        <th>Akcije</th>
                                        <th>Username</th>
                                
                                    
                                   
                                    </tr>
                                </thead>
                                <tbody>
                                  
                                
                                     <tr v-for="i in users">
                                        
                                        <td>
                                             <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <a><button type="button" class="btn btn-outline-secondary" @click="edituser(i)"><i class="fa fa-edit text-success"></i></button></a>
                                                 <button type="button" class="btn btn-outline-secondary" @click="deleteuser(i.user__id)"><i class="icofont-ui-delete text-danger"></i></button>
                                             </div>
                                         </td>
                                   
                                        <td>
                                            {{i.user__username}}
                                          </td>
                                
                                       
                                     </tr>
                                   
                                </tbody>
                            </table>
                     
                        </div>
                    </div>
              </div>
            </div><!-- Row End -->
        </div>
    </div>
     
    
            <div class="modal fade" id="createuser" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Korisnik</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="createuser()">
                <div class="modal-body">
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Username</label>
                        <input type="text" class="form-control" id="sub1" v-model="form.user__username" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Password</label>
                        <input type="text" class="form-control" id="sub1" v-model="form.password">
                    </div>
<br>
                    <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.eventi">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Eventi</label>
</div>
                       </div>

                    
                       <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.klijenti">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Klijenti</label>
</div>
                       </div>

                  
                        </div>

                        <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.glazba">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Glazba</label>
</div>
                       </div>

                    
                       <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.narudzbe">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Narudžbe</label>
</div>
                       </div>

                  
                        </div>

                        <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.meni">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Meni</label>
</div>
                       </div>

                    
                       <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.loyalty">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Loyalty</label>
</div>
                       </div>

                  
                        </div>

                        <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.plan">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Plan</label>
</div>
                       </div>

                    
                       <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.korisnici">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Korisnici</label>
</div>
                       </div>

                  
                        </div>
                        
                        <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.bodovi">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Bodovi</label>
</div>
                       </div>

                    
                       <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.ulaz">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Ulaz</label>
</div>
                       </div>

                  
                        </div>

                        <div class="row g-3 mb-3">
                          <div class="col">
                            <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.form.info">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Info</label>
</div>
                       </div>

                    
             
                  
                        </div>
       
                   
                
                </div>
           
                <div class="modal-footer">
                

                    <button type="submit" class="btn btn-success" style="color:white">Spremi</button>
                </div>
            </form>
            </div>
            </div>
        </div>
        
        <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Izbriši Korisnika?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deleteuserpost()">Izbriši</button>
                </div>
            </div>
            </div>
        </div>



            
        </template>
    </Navigation>
    
            <!-- Body: Body -->
          
    
        
        </template>
        
        
        <script>
        import '../assets/bundles/dataTables.bundle.js';
        import '../assets/bundles/libscripts.bundle.js';


        import $ from 'jquery'
    
        import Navigation from '../components/Navigation.vue'
        import axios from 'axios'
        export default {
          name: 'EventiView',
        
        
          data(){
            return{
            users:[],
            form1:{
                user__username:"",
                oldusername:"",
                password:"",
                eventi:false,
           
                klijenti:false,
                glazba :false,
                narudzbe:false,
                meni:false, 
                loyalty :false,
                plan :false,
                korisnici:false,
                bodovi :false,
                ulaz :false,
                info:false,
        
                klubid:null,
            },
            form:{

            },
            deleteform:{
                klubid:null,
                id:null

            },
          }
        },
        components: {
        Navigation
      },
    
          mounted(){
            
    
            $(".containerloading").show()
            this.getusers()
        
        
          },
          methods:{

            createuser(){
              window.$(".containerloading").show()
              
    
    axios.post('/staff/createuser/', this.form)
    .then((res) => {

      window.$(".containerloading").hide()
      this.getusers()

      if(this.form.edit){
        this.$root.toastnoti('Uspjeh', 'Podaci o korisniku su uređeni', 'uspjeh')
      }
      else{
        this.$root.toastnoti('Uspjeh', 'Korisnik je dodan', 'uspjeh')
      }
        



   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  
deleteuserpost(){
              window.$(".containerloading").show()
              
    
    axios.post('/staff/deleteuser/', this.deleteform)
    .then((res) => {

     
      this.getusers()
      window.$("#dremovetask").modal('hide')
      window.$(".containerloading").hide()
      this.$root.toastnoti('Uspjeh', 'Korisnik je izbrisan', 'greska')




   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  

            deleteuser(id){
        window.$("#dremovetask").modal('show')
        this.deleteform.userid = id

    },
    createform(id){
        this.form = this.form1
        this.form.edit = false
        window.$("#createuser").modal('show')
       

    },
    edituser(user){
        this.form = user
        this.form.oldusername = this.form.user__username
        this.form.edit = true
        window.$("#createuser").modal('show')
        console.log(this.form)
        

    },

     

            async getusers() {
    
    await axios
      .get('/staff/korisnici/'+this.$route.params.klub)
      .then(res => {

       this.users = res.data.users
       console.log(this.users)


    
      $(".containerloading").hide()
        
    
      
      })
      .catch(error => {
        if(error.response.status == 401){
            this.$router.push('/login/')
                    }
        this.$root.toastnoti('Greška', 'Greška pri dohvaćanju korisnika', 'greska')
        $(".containerloading").hide()
      })
    
    },
    
          
          
            
          },
        }
        </script>
        
    <style scoped>

#flexSwitchCheckDefault{
    width:3.5em;height: 2em;
}

.userlabel{
    font-size: 15px;float:left;padding-left: 10px;padding-top: 3px;
}
</style>