import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
    },
    isAuthenticated: false,
    token: '',
    isLoading: false,
    username:"",
    uloga:"",
    ime:"",
    boja:"",
    id:"",
    secboja:"",
    logo:"",
    klubid:null,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 

      if (localStorage.getItem('uloga')) {
        state.uloga = localStorage.getItem('uloga')

    } else {
        state.uloga = ''

    }
    
    if (localStorage.getItem('ime')) {
      state.ime = localStorage.getItem('ime')

  }
  if (localStorage.getItem('boja')) {
    state.boja = localStorage.getItem('boja')

}
if (localStorage.getItem('id')) {
  state.id = localStorage.getItem('id')

}
if (localStorage.getItem('secboja')) {
  state.secboja = localStorage.getItem('secboja')

}

    if (localStorage.getItem('username')) {
      state.username = localStorage.getItem('username')

  } else {
      state.username = ''

  } 
  if (localStorage.getItem('logo')) {
    state.logo = localStorage.getItem('logo')
  
  }
  if (localStorage.getItem('klubid')) {
    state.klubid = localStorage.getItem('klubid')
  
  }

    },
    
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.jelo === item.jelo)
  
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  
    removeFromCart(state, item) {
      console.log(item)
      const index = state.cart.items.findIndex(i => i.id === item.id);
      console.log(index)
  
      if(index !== -1){
        item =  state.cart.items[index];
  
        if(item.quantity > 1){
            item.quantity--; //remove one quantity
        }else{
          state.cart.items.splice(index, 1);
        }
      }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },

    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    setUsername(state, username) {

      state.username = username

  },
  setUloga(state, uloga) {
 

    state.uloga = uloga
},
setIme(state, ime) {

  state.ime = ime
},
setHover(state, boja) {

  state.secboja = boja
},
setBoja(state, boja) {

  state.boja = boja
},
setId(state, id) {
  state.id = id
},
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    clearCart(state) {
      state.cart = { items: [] }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  },
  actions: {
  },
  modules: {
  }
})


