<template>


<Navigation>
    <template #body>

        <!-- Body: Body -->       
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Eventi</h3>
                        <div class="col-auto d-flex w-sm-100">
                            <button type="button" class="btn btn-dark btn-set-task w-sm-100" @click="openmodaladd"><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj Event</button>
                        </div>
                    </div>
                </div>
            </div> <!-- Row end  -->
            <div class="row clearfix g-3">
              <div class="col-sm-12">
                    <div class="card mb-3">
                        <div class="card-body">

                    
                           
                            <table id="myProjectTable" class="table table-hover align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Akcije</th>
                                        <th>Naslov</th>
                                        <th>Lokacija</th> 
                                        <th>Datum</th> 
                                        <th>Reklamirano</th>   
                                   
                                    </tr>
                                </thead>
                                <tbody>
                                  
                                
                                     <tr v-for="event in eventi">
                                 
                                        <td>
                                             <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                <a :href="'/' + this.$route.params.klub +'/details/'+event[4]" style="padding-right: 15px;"><button type="button" class="btn btn-outline-secondary" ><i class="icofont-info text-success"></i></button></a>
                                                 <button type="button" class="btn btn-outline-secondary" @click="this.deletemodal(event[4])"><i class="icofont-ui-delete text-danger"></i></button>
                                             </div>
                                         </td>
                                        <td>
                                            {{event[0]}}
                                          </td>
                                        <td>
                                       
                                            <span class="fw-bold ms-1">{{ event[2] }}</span>
                                        </td>
                                        <td>
                                            {{ event[3] }}
                                          </td>
                                        <td><span class="badge bg-success">Da</span></td>

                                        
                                       
                                     </tr>
                                   
                                </tbody>
                            </table>
                     
                        </div>
                    </div>
              </div>
            </div><!-- Row End -->
        </div>
    </div>
    </template>
</Navigation>
    
    


    <!-- Add Tickit-->
    <div class="modal fade" id="tickadd" tabindex="-1"  aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title  fw-bold" id="leaveaddLabel">Kreiraj novi event</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createEvent()">
            <div class="modal-body">
                <div class="mb-3">
                    <label for="sub" class="form-label">Naslov</label>
                    <input type="text" class="form-control" v-model="form.naslov" id="sub" required>
                </div>
                <div class="deadline-form">
                   
                        <div class="row g-3 mb-3">
                          <div class="col">
                            <label for="depone" class="form-label">Lokacija</label>
                   
                            <select class="form-select" v-model="form.lokacija" required>
                        <template v-for="j in lokacije">
                            <option :value="j[1]">{{j[0]}}</option>
                        </template>
                    
                    </select>               </div>

                    

                          <div class="col">
                            <label for="deptwo" class="form-label">Datum</label>
                            <input type="date" class="form-control" v-model="form.datum" id="deptwo" required>
                          </div>
                        </div>
                      
                   
                </div>
                <div class="mb-3">
                        <label for="exampleFormControlTextarea78" class="form-label">Opis</label>
                        <textarea class="form-control" id="exampleFormControlTextarea78" v-model="form.opis" rows="3" placeholder="Više informacije o eventu" required></textarea>
                    </div>

                    <p>Ostale informacije uređujete iz detaljnog prikaza</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Otkaži</button>
                <button type="submit" class="btn btn-primary">Dodaj</button>
            </div>
        </form>
        </div>
        </div>
    </div>

    
    <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Izbriši event?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deleteevent">Izbriši</button>
                </div>
            </div>
            </div>
        </div>

        <div class="modal fade" id="bodoviform" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Bodovi</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="fa fa-coins text-danger display-2 text-center mt-1"></i><br>
                 
                    <button class="btn btn-success" style="color:white;" @click="toggleskener()">Skeniraj Qr kod</button><br>
                    <input type="text" class="form-control" placeholder="Email" v-model="email"><br>
                    <button class="btn btn-info" style="color:white;" v-show="dodaj" @click="toggleVariable()"> Dodaj Bodove <i class="btn btn-plus"></i></button>
                    <button class="btn btn-danger" style="color:white;" v-show="!dodaj" @click="toggleVariable()"> Skini Bodove <i class="btn btn-minus"></i></button><br>
                    <input type="number" class="form-control" placeholder="Trošak u eurima" v-show="dodaj">
                    <input type="number" class="form-control" placeholder="Koliko bodova" v-show="!dodaj">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success color-fff" style="color:White;">Potvrdi</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    
                </div>
            </div>
            </div>
        </div>


</template>


<script>
import $ from 'jquery'
import '../assets/bundles/dataTables.bundle.js';
import '../assets/bundles/libscripts.bundle.js';



import Navigation from '../components/Navigation.vue'
import { StreamBarcodeReader } from "vue-barcode-reader";


import axios from 'axios'
export default {
  name: 'EventiView',
  components: {
    StreamBarcodeReader,
    Navigation

  },

  data(){
    return{
        eventi:[],
        email:"",
        skeniraj:false,
        dodaj:true,



    data : [

],



        form: {
            datum:"",
            naslov:"",
            opis:"",
            lokacija:"",
            klubid:this.$route.params.klub,
        },
        deleteform:{
            vrsta:"event",
            id:0,
            klubid:this.$route.params.klub,
        },
        root:null,
        lokacije:[]
    }
  },
  mounted(){

    


   
    window.$(".containerloading").show()
   
    this.getEventi()
    this.initdatatable()


  },
  methods:{
    toggleVariable() {
      this.dodaj = !this.dodaj;
    },
    toggleskener() {
      this.skeniraj = !this.skeniraj;
    },

    onDecode(text) {
        this.skeniraj=false
        this.email = text

},

    openbodoviform(){
        window.$("#bodoviform").modal('show')
    },

    deletemodal(id){
        window.$("#dremovetask").modal('show')
        this.deleteform.id = id

    },



    deleteevent(){
    
    axios.post('/deletepost/', this.deleteform)
    .then((res) => {
        this.$root.toastnoti('Uspjeh', 'Event je izbrisan', 'uspjeh')
        window.$("#dremovetask").modal('hide')

        this.eventi = this.eventi.filter(item => {
        if (item[4] === this.deleteform.id) {
          return false; 
        }
        return true
      });

     
        this.updated()


   


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


    initdatatable(newvar){
        $('#myProjectTable')
        .addClass( 'nowrap' )
        .dataTable( {
            responsive: true,
            columnDefs: [
                { targets: [-1, -3], className: 'dt-body-right' }
            ]
        });

        if(newvar){
            window.$(".containerloading").hide()
        }

        

    },

    updated() {
    // Destroy and reinitialize DataTable when data changes
    const dataTable = $('#myProjectTable').DataTable();
    dataTable.destroy();

    setTimeout(() => {
        this.initdatatable(true)
 
      }, 200);
    
  },


  beforeDestroy() {
    // Destroy the DataTable instance when the component is destroyed
    $('#myProjectTable').DataTable().destroy();
  },

  openmodaladd(){
    window.$("#tickadd").modal('show')
  },



createEvent(){
    
            axios.post('/eventpost/', this.form)
            .then((res) => {
                this.eventi.push([this.form.naslov, this.form.opis, this.form.lokacija, this.form.datum, res.data])
                this.updated()
       
                this.form =  {datum:"",naslov:"", opis:"",lokacija:"", klubid:this.$route.params.klub}
                window.$("#tickadd").modal('hide')
                this.$root.toastnoti('Uspjeh', 'Event je napravljen', 'uspjeh')


                    
              })
            .catch((error) => {
              console.log(error)    
             
            })
        },  


    async getEventi() {
   

   await axios
     .get('/eventistaff/'+this.$route.params.klub)
     .then(res => {
       this.eventi = res.data.eventi
       this.lokacije = res.data.lokacije

       

      
       this.updated()


       
   
   
     })
     .catch(error => {
   
      if(error.response.status == 401){
        this.$router.push('/login/')
                    this.$root.toastnoti('Greška', 'Ponovno se prijavite', 'greska')
      
                }
    if(error.response.status == 403){
        this.$root.toastnoti('Greška', 'Nemate pristup za pregled', 'greska')
        this.$router.push('/login/')
    }
   
     })
   
   },
    
  },
}
</script>

<style>

@media screen and (max-width: 767px){
    div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: left !important;
    padding-bottom: 20px;
}

}

#myProjectTable_length{
    display: none;
}

@media only screen and (max-width: 767px){
    #myProjectTable_length, #myProjectTable_filter, #myProjectTable_info, #myTransaction_length, #myTransaction_filter, #myTransaction_info, #patient-table_length, #patient-table_filter, #patient-table_info {
    display: block;
}
}



  @import '../assets/plugin/datatables/responsive.dataTables.min.css';
</style>


<style>
  @import '../assets/plugin/datatables/dataTables.bootstrap5.min.css';
</style>


