import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Eventi from '../views/Eventi.vue'
import EventDetails from '../views/EventDetails.vue'
import Klijenti from '../views/Klijenti.vue'
import Orders from '../views/Orders.vue'
import MusicOrders from '../views/MusicOrders.vue'
import Plan from '../views/Plan.vue'
import Meni from '../views/Meni.vue'
import Loyalty from '../views/Loyalty.vue'
import Korisnici from '../views/Korisnici.vue'
import store from '../store'

const routes = [
  {
    path: '/:login?',
    name: 'login',
    component: HomeView
  },
  {
    path: '/:klub/menu/',
    name: 'menu',
    component: Meni
  },
  {
    path: '/:klub/korisnici/',
    name: 'korisnici',
    component: Korisnici
  },
  {
    path: '/:klub/loyalty/',
    name: 'loyalty',
    component: Loyalty
  },
  {
    path: '/klub/:klub/',
    name: 'Eventi',
    component: Eventi,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/:klub/details/:id',
    name: 'Details',
    component: EventDetails,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/:klub/clients/',
    name: 'Klijenti',
    component: Klijenti,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/:klub/plan/',
    name: 'Plan',
    component: Plan,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/:klub/orders/',
    name: 'orders',
    component: Orders,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/:klub/musicorders/',
    name: 'musicorders',
    component: MusicOrders,
    meta: {
      requireLogin: true
    }
  },
 
 
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    return next('/login/')
  } else {
    next()
  }
})

export default router
