<template>

<Navigation>
    <template #body>

        <!-- Body: Body -->
        <div class="body d-flex py-lg-3 py-md-2">
            <div class="container-xxl">
                
              <br>

                <div class="row justify-content-center">

                    <div class="col-lg-12 col-md-12">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="Invoice-list">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="card mb-3" v-for="i in orders">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum" :class="{'greenorder': i.placeno, 'redorder': !i.placeno}">{{ i.stol }}</span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>{{ i.klub__ime.charAt(0).toUpperCase() + i.klub__ime.slice(1) }}, Stol {{ i.stol }}</span></h6>
                                                        <span class="text-muted" style="font-size: 15px;"><b style="font-weight:bold !important;float: left;">{{ i.pjesma }}<br> </b></span>
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                                    <a class="btn btn-sm btn-success border lift"  style="color: white;" @click="odobri(i.id)" v-if="!i.odobreno">Prihvati</a>
                                                    <a class="btn btn-sm btn-danger border lift"  style="color: white;" @click="odbij(i.id)" v-if="!i.odobreno">Odbij</a>
                                                    <a class="btn btn-sm btn-warning border lift" style="color: white;" @click="zavrsi(i.id)" v-if="i.odobreno">Završi</a>
                                
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card mb-3" v-if="orders && orders.length == 0">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"><i class="fa fa-music"></i></span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>No music orders for now</span></h6>
                                                        <span class="text-muted" style="font-size: 15px;" ><br></span>
                                                    </div>
                                                </a>
                                               
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                            
                                       
                                            </div>
                                        </div>
                                       
                                      
                                      
                           
                                    
                                    </div>
                                </div>  <!-- Row end  -->
                            </div> <!-- tab end  -->
                            <div class="tab-pane fade" id="Invoice-Simple">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="card p-xl-5 p-lg-4 p-0">
                                            <div class="card-body">
                                                <div class="mb-3 pb-3 border-bottom">
                                                    Invoice
                                                    <strong>01/Nov/2020</strong>
                                                    <span class="float-end"> <strong>Status:</strong> Pending</span>
                                                </div>

                                                <div class="row mb-4">
                                                    <div class="col-sm-6">
                                                        <h6 class="mb-3">From:</h6>
                                                        <div><strong>Deo web</strong></div>
                                                        <div>111  Berkeley Rd</div>
                                                        <div>STREET ON THE FOSSE, Poland</div>
                                                        <div>Email: info@deoweb.com</div>
                                                        <div>Phone: +44 888 666 3333</div>
                                                    </div>
                                                    
                                                    <div class="col-sm-6">
                                                        <h6 class="mb-3">To:</h6>
                                                        <div><strong> Colin Web</strong></div>
                                                        <div>45 Larissa Court</div>
                                                        <div>Victoria, BIRDWOODTON</div>
                                                        <div>Email: ColinWeb@gmail.com</div>
                                                        <div>Phone: +66 243 456 789</div>
                                                    </div>
                                                </div> <!-- Row end  -->
                                                
                                                <div class="table-responsive-sm">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Project</th>
                                                                <th>Description</th>
                                                                <th class="text-end">Project Cost</th>
                                                                <th class="text-center">Members</th>
                                                                <th class="text-end">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">1</td>
                                                                <td>Box of Crayons</td>
                                                                <td>Extended License</td>
                                                                <td class="text-end">$1999,00</td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-end">$1999,00</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">2</td>
                                                                <td>Fast Cad</td>
                                                                <td>Instalation and Customization (cost per hour)</td>
                                                                <td class="text-end">$50,00</td>
                                                                <td class="text-center">2</td>
                                                                <td class="text-end">$100,00</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">3</td>
                                                                <td>Hosting</td>
                                                                <td>1 year subcription</td>
                                                                <td class="text-end">$499,00</td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-end">$499,00</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">4</td>
                                                                <td>Platinum Support</td>
                                                                <td>1 year subcription 24/7</td>
                                                                <td class="text-end">$3.999,00</td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-end">$3.999,00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                
                                                <div class="row">
                                                    <div class="col-lg-4 col-sm-5">
                                                    
                                                    </div>
                                                    
                                                    <div class="col-lg-4 col-sm-5 ms-auto">
                                                        <table class="table table-clear">
                                                            <tbody>
                                                                <tr>
                                                                    <td ><strong>Subtotal</strong></td>
                                                                    <td class="text-end">$6.597,00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td ><strong>VAT (10%)</strong></td>
                                                                    <td class="text-end">$659,7</td>
                                                                </tr>
                                                                <tr>
                                                                    <td ><strong>Total</strong></td>
                                                                    <td class="text-end"><strong>$7.256,7</strong></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> <!-- Row end  -->
                
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h6>Terms &amp; Condition</h6>
                                                        <p class="text-muted">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over</p>
                                                    </div>
                                                    <div class="col-lg-12 text-end">
                                                        <button type="button" class="btn btn-outline-secondary btn-lg my-1"><i class="fa fa-print"></i> Print</button>
                                                        <button type="button" class="btn btn-primary btn-lg my-1"><i class="fa fa-paper-plane-o"></i> Send Invoice</button>
                                                    </div>
                                                </div> <!-- Row end  -->
                                            </div>
                                        </div>
                                    </div>
                                </div> <!-- Row end  -->
                            </div> <!-- tab end  -->
                            <div class="tab-pane fade" id="Invoice-Email">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-12">
                                        <div class="d-flex justify-content-center">
                                            <table class="card p-5">
                                                <tr>
                                                    <td></td>
                                                    <td class="text-center">
                                                        <table>
                                                            <tr>
                                                                <td class="text-center">
                                                                    <h2>$48.98 Paid</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center py-2">
                                                                    <h4 class="mb-0">Thanks for using PXL Inc.</h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pt-2 pb-4">
                                                                    <table>
                                                                        <tr>
                                                                            <td>
                                                                                Attn: <strong>Ryan MacLeod</strong> Winston Salem FL 27107<br>
                                                                                Email: RyanmacLeod@gmail.com<br>
                                                                                Phone: +88 123 456 789<br>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="pt-2">
                                                                                <table class="table table-bordered">
                                                                                    <tr>
                                                                                        <td class="text-start">Extended License</td>
                                                                                        <td class="text-end">$ 20.99</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start">1 year subcription</td>
                                                                                        <td class="text-end">$ 19.99</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start">Instalation and Customization</td>
                                                                                        <td class="text-end">$ 8.00</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-start w-80"><strong>Total</strong></td>
                                                                                        <td class="text-end fw-bold">$ 48.98</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pt-2 pb-2 text-center">
                                                                    <a href="#">View in browser</a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="p-0 text-center">
                                                                    PXL Inc. 47 Aurora St. South West, CT 06074
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="mt-3 text-center w-100">
                                                            <tr>
                                                                <td class="aligncenter content-block">Questions? Email <a href="mailto:">info@pixelwibes.com</a></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div> <!-- Row end  -->
                            </div> <!-- tab end  -->
                        </div>
                    </div>

                </div> <!-- Row end  -->
            </div>
        </div>

        <!-- Modal Members-->
        <div class="modal fade" id="addUser" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="addUserLabel">Employee Invitation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="inviteby_email">
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email address" id="exampleInputEmail1" aria-describedby="exampleInputEmail1">
                            <button class="btn btn-dark" type="button" id="button-addon2">Sent</button>
                        </div>
                    </div>
                    <div class="members_list">
                        <h6 class="fw-bold ">Employee </h6>
                        <ul class="list-unstyled list-group list-group-custom list-group-flush mb-0">
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar2.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Rachel Carr(you)</h6>
                                        <span class="text-muted">rachel.carr@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <span class="members-role ">Admin</span>
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icofont-ui-settings  fs-6"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li><a class="dropdown-item" href="#"><i class="icofont-ui-password fs-6 me-2"></i>ResetPassword</a></li>
                                              <li><a class="dropdown-item" href="#"><i class="icofont-chart-line fs-6 me-2"></i>ActivityReport</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar3.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Lucas Baker<a href="#" class="link-secondary ms-2">(Resend invitation)</a></h6>
                                        <span class="text-muted">lucas.baker@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Members
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li>
                                                  <a class="dropdown-item" href="#">
                                                    <i class="icofont-check-circled"></i>
                                                      
                                                    <span>All operations permission</span>
                                                   </a>
                                                   
                                                </li>
                                                <li>
                                                     <a class="dropdown-item" href="#">
                                                        <i class="fs-6 p-2 me-1"></i>
                                                           <span>Only Invite & manage team</span>
                                                       </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icofont-ui-settings  fs-6"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li><a class="dropdown-item" href="#"><i class="icofont-delete-alt fs-6 me-2"></i>Delete Member</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item py-3 text-center text-md-start">
                                <div class="d-flex align-items-center flex-column flex-sm-column flex-md-column flex-lg-row">
                                    <div class="no-thumbnail mb-2 mb-md-0">
                                        <img class="avatar lg rounded-circle" src="assets/images/xs/avatar8.jpg" alt="">
                                    </div>
                                    <div class="flex-fill ms-3 text-truncate">
                                        <h6 class="mb-0  fw-bold">Una Coleman</h6>
                                        <span class="text-muted">una.coleman@gmail.com</span>
                                    </div>
                                    <div class="members-action">
                                        <div class="btn-group">
                                            <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                Members
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                              <li>
                                                  <a class="dropdown-item" href="#">
                                                    <i class="icofont-check-circled"></i>
                                                      
                                                    <span>All operations permission</span>
                                                   </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#">
                                                        <i class="fs-6 p-2 me-1"></i>
                                                           <span>Only Invite & manage team</span>
                                                       </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="btn-group">
                                            <div class="btn-group">
                                                <button type="button" class="btn bg-transparent dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="icofont-ui-settings  fs-6"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-ui-password fs-6 me-2"></i>ResetPassword</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-chart-line fs-6 me-2"></i>ActivityReport</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-delete-alt fs-6 me-2"></i>Suspend member</a></li>
                                                  <li><a class="dropdown-item" href="#"><i class="icofont-not-allowed fs-6 me-2"></i>Delete Member</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
</template>
</Navigation>
    
    </template>
    
    
    <script>
    import $ from 'jquery'
    import Navigation from '../components/Navigation.vue'
    
    import axios from 'axios'
    export default {
      name: 'EventiView',
    
    
      data(){
        return{
        orders:[],
        intervalId:0,
        
      }
    },
    components: {
    Navigation
  },

  beforeRouteLeave(to, from, next) {
    // Stop polling when leaving the route
    clearInterval(this.intervalId);
    next();
  },

      mounted(){

        $(".containerloading").show()

        this.getOrders()

        this.intervalId = setInterval(() => {
            this.getOrders()
      }, 10000);
      
    
    
      },
      methods:{
        async getOrders() {

await axios
  .get('/klub/musicorders/'+this.$route.params.klub)
  .then(response => {
    this.orders = response.data.orders
    console.log(this.orders)



    
   

  $(".containerloading").hide()
    

  
  })
  .catch(error => {
    if(error.response.status == 401){
        this.$router.push('/login/')
                }
    this.$root.toastnoti('Greška', 'Greška pri dohvaćanja narudžba', 'greska')
    $(".containerloading").hide()
  })

},

        odbij(id){
            window.$(".containerloading").show()
            
             axios
  .get('/klub/odbi/'+id)
  .then(response => {
        this.$root.toastnoti('Uspjeh', 'Narudžba je odbijena', 'uspjeh')
        this.orders =  this.orders.filter(list => list.id !== id);

    $(".containerloading").hide()
        

  
  })
  .catch(error => {
    console.log(error)
    this.$root.toastnoti('Greška', 'Greška pri završavanju narudžbe', 'greska')
    $(".containerloading").hide()
  })

           
        },

        odobri(id){
            window.$(".containerloading").show()
            
             axios
  .get('/klub/odobri/'+id)
  .then(response => {
        this.$root.toastnoti('Uspjeh', 'Narudžba je odobrena', 'uspjeh')
        for (let i = 0; i < this.orders.length; i++) {
  if (this.orders[i].id == id) {
    this.orders[i].odobreno = true;
  }
}


    $(".containerloading").hide()
        

  
  })
  .catch(error => {
    console.log(error)
    this.$root.toastnoti('Greška', 'Greška pri završavanju narudžbe', 'greska')
    $(".containerloading").hide()
  })

           
        },

        zavrsi(id){
            window.$(".containerloading").show()
            
             axios
  .get('/klub/zavrsi/'+id)
  .then(response => {
        this.$root.toastnoti('Uspjeh', 'Narudžba je završena', 'uspjeh')
        this.orders =  this.orders.filter(list => list.id !== id);

    $(".containerloading").hide()
        

  
  })
  .catch(error => {
    console.log(error)
    this.$root.toastnoti('Greška', 'Greška pri završavanju narudžbe', 'greska')
    $(".containerloading").hide()
  })

           
        },
      
        
      },
    }
    </script>
    

    
  <style>
  
  .order-card {
      color: #fff;
  }
  
  .bg-c-blue {
      background: linear-gradient(45deg,#4099ff,#73b4ff);
  }
  
  .bg-c-green {
      background: linear-gradient(45deg,#2ed8b6,#59e0c5);
  }
  
  .bg-c-yellow {
      background: linear-gradient(45deg,#FFB64D,#ffcb80);
  }
  
  .bg-c-pink {
      background: linear-gradient(45deg,#FF5370,#ff869a);
  }
  .bg-c-gray {
      background: linear-gradient(45deg,#d3d3d3,#d3d3d3);
  }
  
  
  
  .card {
      border-radius: 5px;
      -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
      border: none;
      margin-bottom: 15px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
  
  .card .card-block {
      padding: 25px;
  }
  
  .order-card i {
      font-size: 26px;
  }
  
  .f-left {
      float: left;
  }
  
  .f-right {
      float: right;
  }
  
  
  </style>
  
  <style scoped>
  
  .greenorder{
    background-color: lightgreen !important;

  }
  .redorder{
    background-color: #ed1650 !important;
  }
  .project {  
  
      max-width: 100%;  
  
  }  
  
  
  .submitbtn{
      font-weight: 400;
      display: inline-block;
      padding: 10px 28px;
      border-radius: 4px;
      transition: 0.5s;
      color: var(--color-white);
      background: var(--color-primary);
      font-family: var(--font-secondary);
  
  }

  .stepnum{
max-height: 1.6em;
  background: gray;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; 
  font-size: 20px !important;

  }
  
  
  </style>
  