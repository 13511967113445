<template>
    <Navigation>
        <template #body>
            <div class="body d-flex py-lg-3 py-md-2">
            <div class="container-xxl">
            <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="card border-0 mb-4 no-bg">
                            <div class="card-header py-3 px-0 d-sm-flex align-items-center  justify-content-between border-bottom">
                                <h3 class=" fw-bold flex-fill mb-0 mt-sm-0" style="text-align: left;padding-left: 30px;">Menu</h3><br>
             
                                <div style="padding:10px"><button type="button" @click="createform()" class="btn btn-dark me-1 mt-1 w-sm-100" ><i class="icofont-plus-circle me-2 fs-6"></i>Dodaj</button></div>
                                
                                <div class="dropdown">
                              
                                    <ul class="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                    <li><a class="dropdown-item" href="#">All</a></li>
                                    <li><a class="dropdown-item" href="#">Task Assign Members</a></li>
                                    <li><a class="dropdown-item" href="#">Not Assign Members</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Row End -->
                <div class="row">
                <div class="col-md-6" v-for="i in menu">
                <div class="card" >
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"><i class="fa fa-martini-glass"></i></span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span> {{ i[0] }}</span></h6>
                                                        <span class="text-muted" style="font-size: 15px;"><b style="font-weight:bold !important;float: left;">{{ i[1] }}€<br> </b></span>
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                              
                                                    <a class="btn btn-sm btn-danger border lift"  style="color: white;" @click="deletemodal(i[2])">Izbriši</a>
                                               
                                
                                                </div>
                                            </div>
                                        </div>
                </div>
            </div>

                <div class="card mb-3" v-if="this.menu && !this.menu.length">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a href="javascript:void(0);" class="d-flex">
                                                    <span class="stepnum"><i class="fa fa-martini-glass"></i></span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span>No items on menu</span></h6><br>
                                                      
                                                        <span class="text-muted" style="font-size: 13px;text-align: left;float: left;" >Users can order selected menu to their table <br></span>
                                                    </div>
                                                </a>
                                               
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                            
                                       
                                            </div>
                                        </div>
                                       
                   
            </div>
        </div>
    
     
    
            <div class="modal fade" id="uploadslika" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header" >
                    <h5 class="modal-title  fw-bold" id="edittickitLabel">Dodaj prozivod</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="createMenu()">
                <div class="modal-body">
                    
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Name</label>
                        <input type="text" class="form-control" id="sub1" v-model="form.ime" required>
                    </div>
                    <div class="mb-3">
                        <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Description</label>
                        <textarea type="text" class="form-control" id="sub1" v-model="form.opis"></textarea>
                    </div>
       
                    <div class="row g-3 mb-3">
                        <div class="col">
                            <label for="sub1" class="form-label" style="float:left;padding-left: 10px;">Price</label>
                        <input type="decimal" class="form-control" id="sub1" v-model="form.cijena" required>
                          </div>
                          <div class="col">
                            <label for="depone" class="form-label">Category</label>
                   
                            <select class="form-select" v-model="form.kategorija" required>
                        <template v-for="j in kategorije">
                            <option :value="j">{{j}}</option>
                        </template>
                    
                    </select>               </div>

                    

                     
                        </div>
                   
                
                </div>
           
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>

                    <button type="submit" class="btn btn-success" style="color:white">Spremi</button>
                </div>
            </form>
            </div>
            </div>
        </div>
        
        <div class="modal fade" id="dremovetask" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title  fw-bold" id="dremovetaskLabel">Makni proizvod sa menija?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body justify-content-center flex-column d-flex">
                    <i class="icofont-ui-rate-remove text-danger display-2 text-center mt-2"></i>
                    <p class="mt-4 fs-5 text-center">Ovaj korak se ne može vratiti</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zatvori</button>
                    <button type="button" class="btn btn-danger color-fff" style="color:White;" @click="deletemenu()">Izbriši</button>
                </div>
            </div>
            </div>
        </div>



            
        </template>
    </Navigation>
    
            <!-- Body: Body -->
          
    
        
        </template>
        
        
        <script>
        import $ from 'jquery'
    
        import Navigation from '../components/Navigation.vue'
        import axios from 'axios'
        export default {
          name: 'EventiView',
        
        
          data(){
            return{
            menu:null,
            kategorije:"",
            form:{
                ime:"",
                kategorija:"",
                opis:"",
                cijena:null,
                klubid:this.$route.params.klub,
            },
            deleteform:{
            vrsta:"meni",
            id:0,
            klubid:this.$route.params.klub,
        },

          }
        },
        components: {
        Navigation
      },
    
          mounted(){
            
           
            $(".containerloading").show()
            this.getMeni()
        
        
          },
          methods:{


            createMenu(){
    
    axios.post('/klub/postproizvod/', this.form)
    .then((res) => {
        this.menu.push([this.form.ime, this.form.cijena, res.data.id ])
        this.form.cijena = null
        this.form.opis = ""
        this.form.ime = ""

        
        window.$("#uploadslika").modal('hide')
        this.$root.toastnoti('Uspjeh', 'Proizvod je dodan na meni', 'uspjeh')


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            deletemenu(){
    console.log(this.deleteform)
    axios.post('/deletepost/', this.deleteform)
    .then((res) => {
        this.$root.toastnoti('Success', 'Item is Removed from list', 'uspjeh')
        window.$("#dremovetask").modal('hide')

        
        this.menu = this.menu.filter(item => {
        if (item[2] === this.deleteform.id) {
          return false; 
        }
        return true
      });


            
      })
    .catch((error) => {
      console.log(error)    
     
    })
},  


            deletemodal(id){
                this.deleteform.id = id
        window.$("#dremovetask").modal('show')


    },
    createform(){
        window.$("#uploadslika").modal('show')


    },
            handleFileChange(event) {
      this.form.img = event.target.files[0];
    },

            async getMeni() {
    
    await axios
      .get('/klub/menu/'+this.$route.params.klub)
      .then(res => {

       this.menu = res.data.menu
       this.kategorije = res.data.kategorije
       console.log(this.kategorije)
       console.log(this.menu)
    
      $(".containerloading").hide()
        
    
      
      })
      .catch(error => {
        if(error.response.status == 401){
            this.$router.push('/login/')
                    }
        this.$root.toastnoti('Greška', 'Greška pri dohvaćanja narudžba', 'greska')
        $(".containerloading").hide()
      })
    
    },
    
          
          
            
          },
        }
        </script>
        
    