<template>

<div id="mytask-layout" class="theme-indigo">

<!-- main body area -->
<div class="main p-2 py-3 p-xl-5 ">
    
    <!-- Body: Body -->
    <div class="body d-flex p-0 p-xl-5">
        <div class="container-xxl">

            <div class="row g-0">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
                    <div style="max-width: 25rem;padding-top: 50px;">
                        <div class="text-center mb-5">
                     
                            <svg xmlns="http://www.w3.org/2000/svg"  width="4rem"  fill="currentColor" class="bi bi-moon-fill" viewBox="0 0 16 16">
  <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
</svg>
                        </div>
                        <div class="mb-5">
                            <h2 class="color-900 text-center">NightWise</h2>
                        </div>
                        <!-- Image block -->
                    
                    </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100" >
                    <div class="w-100 p-3 p-md-5 card border-0 bg-dark1 text-light" style="max-width: 32rem;">
                        <!-- Form -->
                        <form class="row g-1 p-3 p-md-4" @submit.prevent="submitForm">
                            
                            <div class="col-12 text-center mb-1 mb-lg-5">
                                <h1>Sign in</h1>
                                <span>Access your admin dashboard</span><br>
                            </div>
                    <br>
                    <div class="col-12">
                                <div class="mb-2">
                                    <label class="form-label" style="float:left">Club ID</label>
                                    <input type="text" class="form-control form-control-lg" placeholder="" v-model="this.loginform.klubid">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="mb-2">
                                    <label class="form-label" style="float:left">Username</label>
                                    <input type="text" class="form-control form-control-lg" placeholder="" v-model="this.loginform.username">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mb-2">
                                    <div class="form-label">
                                        <span class="d-flex justify-content-between align-items-center">
                                            Password
                                        
                                        </span>
                                    </div>
                                    <input type="password" class="form-control form-control-lg" placeholder="" v-model="this.loginform.password">
                                </div>
                            </div>
                  
                            <div class="col-12 text-center mt-4">
                                <button class="btn btn-lg btn-block btn-light lift text-uppercase" atl="signin" type="submit">SIGN IN</button>
                            </div>
                          
                        </form>
                        <!-- End Form -->
                    </div>
                </div>
            </div> <!-- End Row -->
        </div>
    </div>
</div>
</div>


</template>

<style>
  @import '../assets/css/my-task.style.min.css';

  .auth-h100{
    padding-top: 0 !important;
  }
</style>

<script>
// @ is an alias to /src
import axios from 'axios'
import Navigation from '../components/Navigation.vue'

export default {
  name: 'HomeView',
  components: {
    Navigation

  },
  data(){
    return{
        loginform:{
            username:"",
            password:"",
            klubid:"",
        },

    }
  },

  mounted(){
    if(!this.$route.params.login){
        console.log("ne")
   if(this.$store.state.isAuthenticated && this.$store.state.klubid){
        this.$router.push('/klub/'+this.$store.state.klubid)
    }
    }
    this.$root.getKlub(this.$route.params.klub)
    window.$(".containerloading").hide()

    //if(this.$store.state.isAuthenticated && this.$store.state.klubid){
        //this.$router.push('/'+this.$store.state.klubid)
    //}
  },
  methods:{
    async submitForm() {

   
await axios
    .post("/api-token-auth-staff/login/", this.loginform)
    .then(response => {
      
        const token = response.data.token
        console.log(token)
        this.$store.commit('setToken', token)
        axios.defaults.headers.common["Authorization"] = "Token " + token
        
        localStorage.setItem("username", this.loginform.username)
        localStorage.setItem("klubid", this.loginform.klubid)
 

        var uloga = ""
        if (response.data.superuser){
            uloga = "Admin"
        }else{
            uloga = "Staff"
        }
        this.$store.commit('setUsername', this.loginform.username)
        this.$store.commit('setUloga', uloga)
        localStorage.setItem("uloga", uloga)
        localStorage.setItem("token", token)

  
        const toPath = '/klub/'+this.loginform.klubid
        this.$router.push(toPath)

        this.$root.toastnoti("Uspjeh", "Uspješno ste prijavljeni", "uspjeh")

    })
    .catch(error => {
        this.$root.toastnoti("Greška", error.response.data, "greska")
      

    })
},


  }
}
</script>
<style scoped>
.bg-dark1 {
    background-color: #494B4E !important;
}
</style>